import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { onMessageReceived } from '../../graphql/subscriptions';
import '../../App.css';
import { Row, Form, Button, Col, InputGroup } from 'react-bootstrap';
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import { json, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as yup from "yup";
import { Backdrop, CircularProgress } from '@mui/material';
import { publishMessage } from '../../graphql/mutations';
import ModalActions from '../Static_Forms/ModalActions';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Token } from '@mui/icons-material';
import { toast } from 'react-toastify'
import { CambiarStatus } from "../../api/Flotillas";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
const EDNPOINTEVENT = process.env.REACT_APP_SEI_API_ENDPOINT;
const REACT_APP_HOME = process.env.REACT_APP_HOME;

const client = generateClient();
export const FormPagos = (props) => {
    const { handleBack, data, datosTicket, setData, setShow } = props;
    /* 
    console.log("------DatosTicket", datosTicket)
    console.log("------Datos", data)  
    */
    const navigate = useNavigate();
    console.log(datosTicket)

    const [metodoPago, setMetodoPago] = React.useState("");
    const [value, setValue] = React.useState(metodoPago);
    const [idForm, setIdForm] = useState('Not Assign');
    const [loading, setLoading] = useState(false);
    const [pagoExitoModal, setPagoExitoModal] = useState(false)
    const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleChange = (event) => {
        setValue(event.target.value);
        setMetodoPago(event.target.value)
    };


    const handleCambiarStatus = (id) => {
        const data = {
            "flotillaID": id,
            "status": "Pendiente de Pago"
        }
        CambiarStatus(data).then(result => {
            if (result.ok === true) {
                console.log(result.message)

            } else {
                console.log(result.message)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const obtenerPresupuesto = (token, payload) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
             "flotilla": [
                {
                    "tipoVehiculo": "motos",
                    "cantidad": datosTicket.countMotocicleta.N
                },
                {
                    "tipoVehiculo": "vehiculos",
                    "cantidad": datosTicket.countAutomovil.N
                }
            ],
            "tramite": {
                "nombreCompleto": datosTicket.razon_social.S,
                "tipoMovimiento": "ALFL",
                "domicilio":  payload.tramite.domicilio
            },
            "individual": true
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${ENDPOINT}/calculate`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error !== undefined) {
                    toast.error(result.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setLoading(false);
                } else {
                    setData(result);
                    setShow(false);
                    setLoading(false);
                }

            })
            .catch(error => console.log('error', error));
    }

    const ObtenerPayload =(token)=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "dynamoID": datosTicket.id.S
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch(`${ENDPOINT}/infoSEI/calculate`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.message === undefined){

                 obtenerPresupuesto(token, result.payload); 
            }else{
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
  
        })
          .catch(error => console.log('error', error));
    }
    useEffect(() => {
        const fetchData = async () => {
            const url = `${ENDPOINT}/auth`;

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        // Puedes agregar otros encabezados según sea necesario
                    },
                    // Puedes agregar datos al cuerpo de la solicitud si es necesario
                    // body: JSON.stringify({ key: 'value' }),
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const dataToken = await response.json();
                ObtenerPayload(dataToken.token)
                setLoading(true);
            } catch (error) {
                console.error("Error:token", error.message);
                // Puedes manejar el error según tus necesidades
            }
        };

        // Llama a la función para realizar la solicitud
        fetchData();
    }, []);



    /*   const SubirDomicilio =()=>{
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
  
          var raw = JSON.stringify({
              "flotillaID": datosTicket.id.S,
              "usuarioID": datosTicket.usuarioID.S,
              "municipio": info.municipio,
              "codigo_postal": info.codigo_postal,
              "localidad": info.localidad,
              "colonia": info.colonia,
              "calle": info.calle,
              "estado":info.estado,
              "telefono":info.telefono,
              "numero_exterior": info.numero_exterior,
              "numero_interior": info.numero_interior,
              "datosReferencia": info.datosReferencia,
              "indicacionesEntrega":info.indicaciones,
              "entreCalle1": info.entreCalle1,
              "entreCalle2": info.entreCalle2,
              "nombreDestinatario": `${info.nombre} ${info.apellidos}`
          });
  
          var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
          };
  
          fetch(`${ENDPOINT}/createDireccion`, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                 console.log(result);
                 
  
                
              })
              .catch((error) => console.log("error", error));
      } */


    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
        const year = today.getFullYear(); // Get the full year (4 digits)

        return `${day}/${month}/${year}`;
    }
    const formatearFecha = (fecha) => {
        if (fecha) {
            const date = new Date(fecha);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return null;
    };


    const generarEventoMIT =async (conceptos) => {
        const user = await fetchUserAttributes();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": idForm,
            "payload": {
                "validationRequested": true,
                "data": {
                    "flotillaId": datosTicket.id.S,
                    "referencia": data.referencias[0].referencia,
                    "email": user.email,
                    "razonSocial":datosTicket.razon_social.S,
                    "ticketID": datosTicket.ticketID.N,
                    "padron": {
                        "nombrePadron": "Emplacamiento Exprés"
                    },
                    "user":user.name !== undefined ? user.name : "Contribuyente" ,
                    "estadoCuenta": {
                        "serie": data.serie,
                        "identificador": data.identificador,
                        "tramiteId": data.tramiteId,
                        "loteId": data.loteId,
                        "fechaVencimiento": data.fechaVencimiento,
                        "total":data.total
                    },
                    "conceptos": conceptos
                },
                "userId": user.sub,
                "type": "flotillas",
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${EDNPOINTEVENT}/sei/putEvent`, requestOptions)
            .then(response => response.json())
            .then(result =>{
                console.log(result)
                handleCambiarStatus(datosTicket.id.S)
            }
                 )
            .catch(error => console.log('error', error));
    }

    const GenerarLigaPago = async (tipoPago = null) => {
        setLoading(true)
        const user = await fetchUserAttributes();
        // metodoPago : pago1 : card - pago2 : - pago3 :
        // prepara conceptos con lo de calculo
        const opcionPago = tipoPago ? tipoPago : metodoPago
        const conceptos = data.conceptos?.map(cp => {
            return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
        })
        generarEventoMIT(conceptos);
        //preparar otras referencias
        const otrasRef = data.referencias?.map(referencia => {
            const partes = referencia.recaudacion.split('-');
            const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
            const convenio = parteDespuesDelGuion
            return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
        })

        const variables = opcionPago === "pago1" ?
            {
                "id": idForm,
                "payload": {
                    "user":user.name !== undefined ? user.name : "Contribuyente" ,
                    "paymentMethod": "evo",
                    "email": user.email,
                    "tramite": "Emplacamiento Exprés", //"IFlotillas Foraneas",
                    "reference": `${data.referencias[0].referencia}`, /* "202300011471739766260" */
                    "monto": data.total,
                    "type": "flotillas"
                }
            }
            :
            opcionPago === "pago2" ?
                {
                    "id": idForm,
                    "payload": {

                        "paymentMethod": "spei",
                        "data": {
                            "user":user.name !== undefined ? user.name : "Usuario" ,
                            "loteId": `${data.loteId}`,
                            "total_pagar": data.total, //data.total
                            "tramite": "Emplacamiento Exprés",//"Alta Flotillas Foraneas
                            "rfc": datosTicket.rfc.S,
                            "fecha_generacion": getCurrentDate(),
                            "fecha_vigencia": `${formatearFecha(data.fechaVencimiento)}`,//"19/10/2022",
                            "contribuyente": datosTicket.razon_social.S,
                            "referencia": `${data.referencias[0].referencia}`,
                            "otras_referencias": otrasRef,

                        },
                        "email": user.email,
                        "procedure": "referencia_pago_flotillas"
                    }
                }
                :
                {
                    "id": idForm,
                    "payload": {
                        "paymentMethod": "spei",
                        "data": {
                            "user":user.name !== undefined ? user.name : "Usuario" ,
                            "contribuyente": datosTicket?.razon_social?.S,
                            "fecha_generacion": getCurrentDate(),
                            "rfc": datosTicket?.rfc?.S,
                            "fecha_vigencia": `${formatearFecha(data.fechaVencimiento)}`,//"19/10/2022",
                            "movimiento": "FLOTILLAS FORANEAS",
                            "tramite": "FLOTILLAS FORANEAS",//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                            "instrumento_pago": "referencia bancaria",
                            "base_impuesto": "",//"5%",
                            "periodo": "",
                            "tipo_declaracion": "",
                            "otros_conceptos": conceptos,
                            "total_pagar": data.total
                        },
                        "email": user.email,
                        "procedure": "presupuesto_FlotillasForaneas"

                    },
                };
        const Data = await client.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
        console.log("Data :>> ", Data, variables);
    }
    useEffect(() => {
        generateID()
    }, [])
    const generateID = async () => {
        const {
            username,
          } = await getCurrentUser();
        setIdForm(`PagoAltaVehicular_${username}_${Date.now()}`)
    }

    useEffect(() => {
        const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
            .subscribe({
                next: (event) => {
                    const receivedMessage = event.data.onMessageReceived;

                    //parse json on payload response. - authToken - taskToken --- rfc

                    const dataRec = JSON.parse(receivedMessage.payload)


                    if (dataRec?.procedure === "paymentUrl") {
                        if (dataRec.response.cd_response === "error") {
                            setLoading(false);
                            //AlertsAction("error")
                            setPagoExitoModal(false)
                            setPagoFallidoModal(true)
                            return
                        }
                       
                        if (dataRec.response.cd_response === "success") {
                            //AlertsAction("success")
                            setLoading(false);
                            setPagoExitoModal(true)
                            setPagoFallidoModal(false)
                            const datosPago = {
                                sessionId: dataRec.response.session.sessionId,
                                version:dataRec.response.session.version,
                                data: data
                            }
                            navigate("/tramite/Pagos-MIT", { state: datosPago })
                            /* window.open(dataRec.response.nb_url) */
                        }
                    }
                    if (dataRec.Procedure === "referencia_pago_flotillas" || dataRec.Procedure === "presupuesto_FlotillasForaneas") {
                        console.log("REP", dataRec.Procedure);
                        setLoading(false);
                        window.open(dataRec.TXTBody);
                        if (dataRec.Procedure === "referencia_pago_flotillas") {
                            setLoading(false);
                            setPagoExitoModal(true)
                            setPagoFallidoModal(false)
                        }
                        return
                    }



                },
                error: (error) => {
                    console.error('Subscription error', error);
                },
            });


        return () => {
            subscription.unsubscribe();
        };
    });

    const validationSchema = yup.object().shape({
        terms: yup.bool().required().oneOf([true], 'Acepta Términos y Condiciones'),
    });
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true);
            GenerarLigaPago()

        },
        initialValues: {
            terms: false
        },
    });
    return (
        <>


            <FormControl className='FormPago' style={{ backgroundColor: "#D4E0E0", width: "100%", borderRadius: "10px", padding: "10px", marginTop: "10px" }}>
                <FormLabel style={{ display: "flex", justifyContent: "flex-start", textTransform: "uppercase", margin: "10px", color: "black" }} id="demo-controlled-radio-buttons-group" >Selecciona un método de pago</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    <div style={{ backgroundColor: "white", margin: "5px", borderRadius: "10px", display: "flex", justifyContent: "space-between", padding: "10px" }}>
                        <label style={{ display: "grid", alignContent: "center" }}>
                            Tarjeta de Crédito/Débito

                        </label>
                        <div style={{ display: "flex" }}>
                            <div style={{ margin: "5px" }}>

                                <svg width="55" height="19" viewBox="0 0 55 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.3315 0.85175L23.7115 17.7739H19.3341L22.9547 0.85175H27.3315ZM45.749 11.7784L48.0537 5.42359L49.3798 11.7784H45.749ZM50.6331 17.7739H54.6822L51.1489 0.85175H47.4115C46.5717 0.85175 45.8631 1.34021 45.5476 2.09326L38.9802 17.7739H43.5772L44.4898 15.2469H50.1061L50.6331 17.7739ZM39.2084 12.2487C39.2271 7.78235 33.0319 7.53651 33.0747 5.54142C33.0876 4.93352 33.6666 4.28866 34.9311 4.12316C35.5593 4.04122 37.2866 3.97855 39.248 4.88103L40.016 1.29254C38.9625 0.91013 37.6069 0.542175 35.9209 0.542175C31.5943 0.542175 28.549 2.84256 28.5232 6.13647C28.4959 8.57235 30.6972 9.93169 32.356 10.7415C34.0613 11.5711 34.6344 12.1025 34.6269 12.8443C34.6156 13.9803 33.2665 14.4805 32.0078 14.5003C29.8065 14.5351 28.5291 13.9063 27.511 13.4318L26.7178 17.1397C27.7402 17.6094 29.6282 18.0176 31.5868 18.0385C36.1849 18.0385 39.1934 15.7665 39.2084 12.2487ZM21.0763 0.85175L13.984 17.7739H9.35593L5.86599 4.26885C5.6539 3.43707 5.46965 3.13285 4.82533 2.78203C3.77396 2.21163 2.03596 1.67549 0.506836 1.34343L0.611277 0.85175H8.05979C9.00886 0.85175 9.86313 1.48375 10.0784 2.5769L11.922 12.3697L16.4772 0.85175H21.0763Z" fill="#1434CB" />
                                </svg>
                            </div>
                            <div style={{ margin: "5px" }}>

                                <svg width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.3811 3.7324H15.9539V24.2675H27.3811V3.7324Z" fill="#FF5F00" />
                                    <path d="M16.6795 13.9999C16.6777 12.0222 17.1259 10.0701 17.9901 8.2912C18.8543 6.51233 20.1118 4.95339 21.6676 3.73239C19.741 2.21805 17.4272 1.27631 14.9907 1.0148C12.5543 0.753293 10.0933 1.18255 7.88929 2.25354C5.68523 3.32453 3.82696 4.99403 2.52687 7.07121C1.22679 9.14839 0.537354 11.5494 0.537354 13.9999C0.537354 16.4504 1.22679 18.8514 2.52687 20.9286C3.82696 23.0058 5.68523 24.6753 7.88929 25.7463C10.0933 26.8173 12.5543 27.2465 14.9907 26.985C17.4272 26.7235 19.741 25.7818 21.6676 24.2674C20.1118 23.0464 18.8543 21.4875 17.9901 19.7086C17.1259 17.9298 16.6777 15.9776 16.6795 13.9999Z" fill="#EB001B" />
                                    <path d="M42.7971 13.9999C42.7972 16.4504 42.1079 18.8514 40.8079 20.9286C39.5078 23.0058 37.6496 24.6753 35.4456 25.7463C33.2416 26.8173 30.7807 27.2465 28.3443 26.985C25.9078 26.7235 23.5941 25.7818 21.6676 24.2674C23.222 23.0452 24.4785 21.486 25.3426 19.7074C26.2066 17.9288 26.6556 15.9773 26.6556 13.9999C26.6556 12.0226 26.2066 10.071 25.3426 8.29241C24.4785 6.51382 23.222 4.95462 21.6676 3.73239C23.5941 2.21805 25.9078 1.27631 28.3443 1.0148C30.7807 0.753293 33.2416 1.18257 35.4456 2.25357C37.6496 3.32457 39.5078 4.99407 40.8079 7.07125C42.1079 9.14843 42.7972 11.5495 42.7971 13.9999Z" fill="#F79E1B" />
                                    <path d="M41.5512 22.0925V21.6721H41.7207V21.5864H41.289V21.6721H41.4586V22.0925H41.5512ZM42.3894 22.0925V21.5856H42.257L42.1048 21.9342L41.9526 21.5856H41.8201V22.0925H41.9136V21.7101L42.0563 22.0398H42.1532L42.296 21.7093V22.0925H42.3894Z" fill="#F79E1B" />
                                </svg>
                            </div>
                            <FormControlLabel value="pago1" style={{ width: "20px", heidth: "20px" }} control={<Radio />} />
                        </div>

                    </div>

                    <div className='bg-white' style={{ backgroundColor: "white", height: "58px", margin: "5px", borderRadius: "10px", display: "flex", justifyContent: "space-between", padding: "10px" }}>
                        <label style={{ display: "grid", alignContent: "center" }}>
                            Transferencia SPEI
                        </label>
                        <FormControlLabel value="pago2" style={{ width: "20px", heidth: "20px" }} control={<Radio />} />
                    </div>

                </RadioGroup>
                <Form onSubmit={formik.handleSubmit}>

                    <Form.Group className="position-relative mb-3">
                        <InputGroup>

                            <Form.Check
                                required
                                name="terms"
                                label={<label>Para realizar tu pago es necesario que aceptes los <a rel='noopener noreferrer' href={REACT_APP_HOME} >Términos y condiciones</a> del Portal de pagos del Gobierno del Estado de Durango</label>}
                                style={{ float: "none" }}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.terms && !!formik.errors.terms}
                                feedback={formik.errors.terms}
                                feedbackType="invalid"
                                id="validationFormik106"
                                feedbackTooltip
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </FormControl>

            {/* <Row className="mb-3 mt-3">
                <Form.Group as={Col} className="d-grid gap-2">
                    <Button type="submit" variant="secondary" onClick={()=>SubirDomicilio()}>Direccion</Button></Form.Group>
            </Row>  */}
            {/*  <Row className="mb-3 mt-3">
                <Form.Group as={Col} className="d-grid gap-2">
                    <Button type="submit" variant="secondary" onClick={()=>GenerarLigaPago("presupuesto_FlotillasForaneas")}>Presupuesto </Button></Form.Group>
            </Row> */}
            <Row className="mb-3 mt-3">
                <Form.Group as={Col} className="d-grid gap-2">
                    <Button type="submit" variant="secondary" onClick={() => formik.handleSubmit()}> { value === "pago1" ?"Proceder al Pago" : value === "pago2"? "Descargar Referencia de pago": "Seleccione una opción" }</Button></Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} className="d-grid gap-2">

                    <Button variant="link" onClick={() => handleBack()}> Regresar</Button>
                </Form.Group>
            </Row>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <h1>Esperando Respuesta</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ModalActions
                title={'Se ha generado la referencia de pago con éxito'}
                content={''} icon={'check'}
                show={pagoExitoModal}
                onHide={() => { setPagoExitoModal(false);  }}
                buttonAction={() => { setPagoExitoModal(false);  }}
            />
            <ModalActions
                title={'Hubo un problema'}
                content={'por favor, vuelve a intentarlo'} icon={'error'}
                desc={errorMessage}
                show={pagoFallidoModal}
                onHide={() => { setPagoFallidoModal(false); setErrorMessage('') }}
                buttonAction={() => { setPagoFallidoModal(false); setErrorMessage('') }}
            />
        </>
    )
}
