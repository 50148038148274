import React from "react";
// import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Scanner } from '@yudiel/react-qr-scanner';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalActions from "./ModalActions";
import { Text, Loader, View } from "@aws-amplify/ui-react";
import {
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  Accordion,
  Table,
  Modal,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const ENDPOINT =
  "https://yoz13sh4oa.execute-api.us-east-1.amazonaws.com/dev/servicios/data";

function ValidatorQR() {
  const [ref, setRef] = React.useState("");
  const [info, setInfo] = React.useState("");
  const [helpView, setHelpView] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [manual, setManual] = React.useState('');
  const [scan, setScan] = React.useState(false);
  const [hide, setHide] = React.useState(true);

  // const handleScan = (data) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({ referencia: data });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(`${ENDPOINT}/reference`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.transaction === undefined) {
  //         toast.info(result.message, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         setRef(data);
  //         setError(null);
  //       } else {
  //         console.log(result);
  //         toast.success(
  //           "Los datos de la referencia han sido consultados correctamente",
  //           {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //           }
  //         );

  //         setInfo(result.transaction);
  //         setRef(data);
  //         setError(null);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("No se encontró la referencia en la base de datos", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       setError(error);
  //     });
  // };

  const handleValidate = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ referencia: data });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/use-reference`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.transaction === undefined) {
          toast.info(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setRef(data);
          setError(null);
          console.log(data);
        } else {
          console.log(result);
          toast.success(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setInfo(result.transaction);
          setRef(data);
          setError(null);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("No se encontró la referencia en la base de datos", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setError(error);
      });
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalizeString(str) {
    const lowercaseWords = ["de", "a", "m", "p"]; // Add any other words you want to keep lowercase
    return str.replace(/\b\w+\b/g, function (word) {
      return lowercaseWords.includes(word.toLowerCase())
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  }

  const handleManual = (manual) => {
    if (manual.trim() === '') {
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ referencia: manual });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${ENDPOINT}/reference`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.transaction === undefined) {
          toast.info(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setRef(manual);
          setError(null);
          setHelpView(true);

        } else {
          console.log(result);
          toast.success(
            "Los datos de la referencia han sido consultados correctamente",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setInfo(result.transaction);
          setRef(manual);
          setError(null);
          setHelpView(true);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("No se encontró la referencia en la base de datos", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setHelpView(true)
        setError(error);
        
      });
      

  };

  const handleManualChange = (event) => {
    setManual(event.target.value);
  };
  //   const handleValidate = (validation) => {
  //     var valHeaders = new Headers();
  //     valHeaders.append("Content-Type", "application/json");

  //     var rawRef = JSON.stringify({ referencia: validation });

  //     var sendId = {
  //       method: "POST",
  //       headers: valHeaders,
  //       body: rawRef,
  //       redirect: "follow",
  //     };

  //     fetch(ENDPOINTPOST, sendId)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // Handle the response here
  //       console.log(result);
  //       // Example handling of response:
  //       if (result.success) {
  //         // Do something if the request was successful
  //         toast.success("Validation successful", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       } else {
  //         // Handle the case where validation fails
  //         toast.error("result.message", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("Error occurred during validation", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     });
  // };

  return (
    <>
      {/*<div> MANUAL PERO CUANDO SALIA ARRIBA DEL TITULO
        <Row>
          <FormLabel>Referencia o Folio Digital</FormLabel>
          <FormControl 
          type="text" 
          // placeholder="Enter text here"
          value={manual}
          onChange={handleManualChange}  />
          <Button onClick={handleManual}>Verificar</Button>
        </Row>
      </div>*/}
      <div
        className="Lector"
        style={{ margin: "1rem auto 4rem auto", width: "50%" }}
      >
        {/* <h1 style={{textAlign: "center" }}>Validador de códigos QR</h1> */}
        <View className="FormTitle">Validador de Documentos </View>
        <View className="FormDescripcion">
          Esta herramienta ayudará en la verificación de los documentos
          generados por el portal de pagos, ya sea de forma manual o mediante códigos QR.
        </View>
        <div>
        <Row>
          <FormLabel>Referencia o Folio Digital</FormLabel>
          <FormControl 
          type="text" 
          // placeholder="Enter text here"
          value={manual}
          onChange={handleManualChange}  />
          <Button onClick={() => handleManual (manual)}>Verificar</Button>
        </Row>
      </div>
      <div className="Lector-QR" style={{ margin: "1rem auto 4rem auto" }}>
      <h1></h1>
      <Button
        onClick={() => {
          setScan(!scan);
          setHide(!hide);
        }}
        variant="outline-primary"
        style={{ margin: "0 auto 1rem auto", width: "7rem" }}
      >
        {scan ? "Cerrar escáner" : "Comenzar escaneo de código QR"}
      </Button>
      </div>
      {!hide && 
        <Scanner
          facingMode="environment"
          scanDelay={2000}
          // onDecode={(result) => handleScan(result)}
          onDecode={(result) => {
            handleManual(result);
            // setHelpView(true);
          }}
          onError={(error) => console.log(error?.message)}
          stopDecoding={hide}
          videoStyle={{
            margin: "auto auto auto auto",
            width: "100%",
            alignContent: "center",
          }}
        />
}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "left",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {console.log(ref)}
        <HelpOutlineIcon
          style={{ padding: "5px", width: "30", height: "30" }}
          className="cursor- cursor-pointer"
          onClick={() => setHelpView(true)}
        />{" "}
        <Modal
          show={helpView}
          animation={false}
          onHide={() => setHelpView(false)}
          centered
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {error
                ? "La referencia no se encontró o no es válida"
                : "La referencia ha sido encontrada y estos son los datos de la transacción:"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* if info.periodo is trimestral:
            else if info.periodo is anual: */}
            {error ? (
              <div>
                <p>Se encontró un error al escanear el código QR.</p>
                <p>Es posible que el código QR no corresponda a una referencia del portal de pagos
                  o no se haya encontrado en la base de datos.
                </p>
                {/* <p>Error Details: {error.message}</p> */}
              </div>
            ) : (
              <div style={{ textAlign: "left", display: "grid" }}>
                <label>
                  <span style={{ fontWeight: "bold" }}>Referencia:</span> {ref}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Monto pagado:</span> $
                  {info?.amount}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Fecha de pago:</span>{" "}
                  {info?.createdAt
                    ? capitalizeString(
                        new Date(info.createdAt).toLocaleString("es-MX", {
                          timeZone: "America/Monterrey",
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      )
                    : ""}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>
                    Tipo de declaración:
                  </span>{" "}
                  {info?.declarationType}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>
                    Fecha de expiración:
                  </span>{" "}
                  {info?.expirationDate
                    ? capitalizeString(
                        new Date(info.expirationDate).toLocaleString("es-MX", {
                          timeZone: "America/Monterrey",
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      )
                    : ""}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>
                    Nombre del contribuyente:
                  </span>{" "}
                  {info?.name}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Periodo:</span>{" "}
                  {info?.periodType}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Mes Inicio:</span>{" "}
                  {info?.startMonth
                    ? capitalizeFirstLetter(
                        new Date(0, info.startMonth - 1).toLocaleDateString(
                          "es-ES",
                          { month: "long" }
                        )
                      )
                    : ""}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Mes Final:</span>{" "}
                  {info?.endMonth
                    ? capitalizeFirstLetter(
                        new Date(0, info.endMonth - 1).toLocaleDateString(
                          "es-ES",
                          { month: "long" }
                        )
                      )
                    : ""}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>RFC:</span> {info?.rfc}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Trámite:</span>{" "}
                  {info?.tramite}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Método de pago:</span>{" "}
                  {info?.source}
                </label>
                <label>
                  <span style={{ fontWeight: "bold" }}>Año:</span> {info?.year}
                </label>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setHelpView(false)}>
              Aceptar
            </Button>
            {!error && (
              <Button variant="primary" onClick={() => handleValidate(ref)}>
                Quemar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
}

export default ValidatorQR;
