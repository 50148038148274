import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import { publishMessage } from "../../graphql/mutations";
import * as yup from "yup";
import { isMobile } from "react-device-detect";
import { Button as ButtonB } from "react-bootstrap";
import ResumenCard from "./ResumenCard";
import Swal from "sweetalert2";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { toast, ToastContainer } from "react-toastify";
import ModalActions from "./ModalActions";
import { onMessageReceived } from "../../graphql/subscriptions";
import FormPagos from "../FormPagos";
import { useTramiteDataGeneral } from "../../TramiteContext";
import Iframe from "react-iframe";
import { Button, Loader, View } from "@aws-amplify/ui-react";
import { Backdrop, CircularProgress } from "@mui/material";
import imagenTarjeta from "../../assets/mensaje_tarjeta.png";
import PantallaExitoImpuestos from "../PantallaExitoImpuestos";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormEvo from '../FormEvo';
const client = generateClient();

const steps = ["Información", "Formas de pago"];
const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT 

export const FormActaNacimiento = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { data, setCalculo, setData, calculo } = useTramiteDataGeneral();
  // const [data, setDataConsulta] = useState({});
  const [calculoData, setCalculoData] = useState({});
  const [idForm, setIdForm] = useState("Not Assign");
  const [skipped, setSkipped] = React.useState(new Set());
  const [metodoPago, setMetodoPago] = useState("pago1");
  const [pagoExitoModal, setPagoExitoModal] = useState(false);
  const [datoVacio, setDatoVacio] = useState(false);
  const [pagoFallidoModal, setPagoFallidoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCalculo, setLoadingCalculo] = useState(false);
  const [loadingPago, setLoadingPago] = useState(false);
  const [respuestaPago, setRespuestaPago] = useState({});
  const [pantallaExito, setPantallaExito] = useState(false);
  const [respuestaRecibo, setRespuestaRecibo] = useState({});
  const [decLimiteAlcanzado, setDecLimiteAlcanzado] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ligaPagoMit, setLigaPagoMit] = useState(null);
  const [continuarPago, setContinuarPago] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [expanded, setExpanded] = React.useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [versionId, setVersionId] = useState(null);

  useEffect(() => {
    generateID();
  }, []);
  const generateID = async () => {
    const user = await getCurrentUser();
    const id = `ActadeNacimiento_${user.username}_${Date.now()}`;
    setIdForm(id);
    return id;
  };


  const validationSchema = yup.object().shape({
    nombre: yup
    .string()
    .transform(function (value) {
      return value ? value.toUpperCase() : value;
    })
    .required("Este Campo es Obligatorio"),
    primerApellido: yup
    .string()
    .transform(function (value) {
      return value ? value.toUpperCase() : value;
    })
    .required("Este Campo es Obligatorio"),
    segundoApellido: yup.string().transform(function (value) {
      return value ? value.toUpperCase() : value;
    }),
    fechaNacimiento: yup.string().required("Este Campo es Obligatorio").matches(/^(0?[1-9]|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/(19|20)\d{2}$/, 'La fecha debe estar en el formato dd/mm/aaaa'),
    sexo: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
    nombreRegistro: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
    primerApellidoRegistro: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
    segundoApellidoRegistro: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      ,
  });

 
  const handleNext = async () => {
    await generateID(); 
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month (0-11) and add 1, then pad with leading zero
    const year = today.getFullYear(); // Get the full year (4 digits)

    return `${day}/${month}/${year}`;
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: values => { 
      
    },
    initialValues: {
      nombre: "",
      primerApellido: "",
      segundoApellido: "",
      fechaNacimiento: "",
      sexo: "",
      nombreRegistro: "",
      primerApellidoRegistro: "",
      segundoApellidoRegistro: "",
    },
  });

  const handleChange = (event) => {
    formik.handleChange(event);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    formattedValue = formattedValue.replace(/[^\d]/g, "");

    if (formattedValue.length > 2) {
      formattedValue = formattedValue.substring(0, 2) + '/' + formattedValue.substring(2);
    }
    if (formattedValue.length > 5) {
      formattedValue = formattedValue.substring(0, 5) + '/' + formattedValue.substring(5, 9);
    }

    formik.setFieldValue(name, formattedValue);
  };
  const formatearFechaNac = (fechaNacimiento) => {
    if (fechaNacimiento) {
      const [day, month, year] = fechaNacimiento.split('/').map(Number);

      const date = new Date(year, month - 1, day); // cambia el formato ingresado de fecha por el usuario

      const formattedDay = String(date.getDate()).padStart(2, '0');
      const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
      const formattedYear = date.getFullYear();
      return `${formattedYear}/${formattedMonth}/${formattedDay}`;
    }
    return null;
  };
  const formatearFecha = (fecha) => {
    if (fecha) {
      const date = new Date(fecha);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return null;
  };
  const ValidarNombres = (dataFormik, data) => {
    if(data === undefined){
      return false
    }else{

      if (dataFormik.segundoApellidoRegistro !== "") {
        
      if ((data.padre === dataFormik.nombreRegistro && data.primer_AP_PADRE === dataFormik.primerApellidoRegistro && data.segundo_AP_PADRE === dataFormik.segundoApellidoRegistro) || (data.madre === dataFormik.nombreRegistro && data.primer_AP_MADRE === dataFormik.primerApellidoRegistro && data.segundo_AP_MADRE === dataFormik.segundoApellidoRegistro)) {
        return true;
      } else {
        Swal.fire({
          title: "Advertencia",
          text: "Los datos proporcionados no coinciden",
          icon: "warning",
          confirmButtonColor:"#6c757d"
        });
        setData("")
        setCalculo(null);
        setCalculoData(null);
        setLoading(false);
        return false;
      }
    } else {
      if ((data.padre === dataFormik.nombreRegistro && data.primer_AP_PADRE === dataFormik.primerApellidoRegistro) || (data.madre === dataFormik.nombreRegistro && data.primer_AP_MADRE === dataFormik.primerApellidoRegistro)) {
        return true;
      } else {
        Swal.fire({
          title: "Advertencia",
          text: "Los datos proporcionados no coinciden",
          icon: "warning",
         confirmButtonColor:"#6c757d"
        });
        setData("")
        setCalculo(null);
        setCalculoData(null);
        setLoading(false);
        return false;
      }
      
    }
  }
  }
  const consultaEndpoint = async () => {
    const data = formik.values
    if (data.nombre === "" || data.primerApellido === "" || data.sexo === "" || data.fechaNacimiento === "" || data.nombreRegistro === "" || data.primerApellidoRegistro === "") {
      console.log("Falta un elemento");
    } else {
      setLoading(true);
      const authEndpoint = `${ENDPOINT_SEI}/sei/auth`;
      const endpoint = `${ENDPOINT_SEI}/sei/servicios/validate`;

      const requestData = {
        "servicio": {
          "claveServicio": "SERV361",
        },
        "parametroServicio": {
          "nombre": data.nombre,
          "apellidoP": data.primerApellido,
          "apellidoM": data?.segundoApellido || '',
          "fechaNac": formatearFechaNac(data.fechaNacimiento),
          "tipoActa": "NACIMIENTO",
          "nombreContribuyente": data.nombre + " " + data.primerApellido + " " + data?.segundoApellido || '',
        },
      };
      console.log("requestData", requestData);

      try {
        // Primera consulta para obtener la autenticación
        const authResponse = await fetch(authEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

          },
          body: JSON.stringify({}),
        });

        if (!authResponse.ok) {
          throw new Error(`Error en la primera consulta: ${authResponse.status}`);
        }
        const authData = await authResponse.json();
        setAuthToken(authData.token);


        // Segunda consulta utilizando el token de autenticación obtenido
        const validateResponse = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData.token}`,
          },
          body: JSON.stringify(requestData),
        }); console.log("validate response", validateResponse);

        if (!validateResponse.ok) {
          const errorData = await validateResponse.json();
          setErrorMessage(errorData.error);
          setPagoFallidoModal(true);


          throw new Error(
            setLoading(false)
              `Error en la segunda consulta: ${validateResponse.status}`
          );
        }

        const validateData = await validateResponse.json();
        console.log("validateData", validateData)
        
        const validar = ValidarNombres(data, validateData[0]);
        if (validateData.length === 0) {
          console.log("no hay datos")
          setCalculo(null);
          setCalculoData(null);
          setLoading(false)
          setDatoVacio(true)
          setData(validateData);
        } else {
            if ( validar) {
              setData(validateData);
            console.log("hay datos")
            calcularServicioSei()
            setLoadingCalculo(true); 
            }
          }
          
        


        // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
      } catch (error) { console.log("error", error.message) }
    };
  };
  const calcularServicioSei = async (generarEstado = false) => {
    const data = formik.values;
    const idToast = toast.loading("Obteniendo datos del importe a pagar");
    // if (data.nombre === "" || data.primerApellido === "" || data.sexo === "" || data.fechaNacimiento === "") {
    //   console.log("faltan datos");
    // } else {
    setLoading(false);
    const endpoint = `${ENDPOINT_SEI}/sei/servicios/calculate`;
    const authEndpoint = `${ENDPOINT_SEI}/sei/auth`;



    const requestData = {
      claveServicio: "SERV361",
      nombreContribuyente: data.nombre + " " + data.primerApellido + " " + data?.segundoApellido || '', //Nombre del tramitante (Interno)

      listaParametrosServicios: null,
    }; console.log("requestData de calculate", requestData);

    try {
      // Primera consulta para obtener la autenticación
      const authResponse = await fetch(authEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (!authResponse.ok) {
        throw new Error(
          toast.update(idToast, { render: "Ocurrio un erro inesperado.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, })
          `Error en la primera consulta: ${authResponse.status}`

        );
      }
      const authData = await authResponse.json();
      setAuthToken(authData.token);

      // Segunda consulta utilizando el token de autenticación obtenido
      const validateResponse = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authData.token}`,
        },
        body: JSON.stringify(requestData),
      });
      if (!validateResponse.ok) {
        const errorData = await validateResponse.json();
        toast.dismiss(idToast.current);

        setErrorMessage(errorData.error);
        setPagoFallidoModal(true);
         setLoadingCalculo(false); 
        setLoading(false);
        throw new Error(
          setLoadingCalculo(false)
            `Error en la segunda consulta: ${validateResponse.status}`
        );
      }
      const validateData = await validateResponse.json();
      console.log("validate response de calculate", validateData)

      // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
      // setPayloadDeclaraciones(validateData.payload)
      setCalculo({ ...validateData });
      setCalculoData(validateData);
      setLoading(false);
      toast.update(idToast, { render: "Datos obtenidos con éxito", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });
      setLoadingCalculo(false); 
      // setPayloadPago(validateData.payload)
    } catch (error) {
      setLoadingCalculo(false)
      console.error("Error en la consulta:", error.message);
    }



  };

  const generarTransaccion = async () => {
    console.log("putevent", idForm);
    const user = await fetchUserAttributes();
    try {
      const transaccionEndpoint = `${ENDPOINT_SEI}/sei/putEvent`;
      const conceptos = calculoData.concepto?.map((cp) => {
        return {
          clave: cp.concepto,
          concepto: cp.descripcion,
          importe: cp.monto,
        };

      }); console.log("conceptos", conceptos);

      const response = await fetch(transaccionEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          id: idForm,
          payload: {
            // 

            "data": {
              "razonSocial": data[0]?.nombre + " " + data[0]?.primer_AP + " " + data[0]?.segundo_AP,
              "user": user.name !== undefined ? user.name : "Contribuyente",
              "email": user.email,
              "padron": {
                "nombrePadron": "Actas de Nacimiento",
              },

              "estadoCuenta": {
                "serie": calculo?.serie,
                "identificador": calculo?.identificador,
                "tramiteId": calculo?.tramiteId,
                "loteId": calculo?.loteId,
                "fechaVencimiento": calculo?.fechaVencimiento,
                "total": calculo?.importe, // -- cambio
              },
              "conceptos": conceptos,
              "document": {
                "tomo": data[0]?.tomo || "",
                "nombre": data[0]?.nombre || "",
                "primer_AP": data[0]?.primer_AP || "",
                "segundo_AP": data[0]?.segundo_AP || "",
                "localidad": data[0]?.localidad || "",
                "municipio": data[0]?.municipio || "",
                "nacionalidad": data[0]?.nacionalidad || "",
                "fecha_NAC": data[0]?.fecha_NAC || "",
                "oficialia": data[0]?.oficialia || "",
                "libro": data[0]?.libro || "",
                "acta": data[0]?.acta || "",
                "num_OFI": data[0]?.num_OFI || "",
                "local_OFI": data[0]?.local_OFI || "",
                "munic_OFI": data[0]?.munic_OFI || "",
                "mun_OFI": data[0]?.mun_OFI || "",
                "estado_OFI": data[0]?.estado_OFI || "",
                "crip": data[0]?.crip || "",
                "serie": data[0]?.serie || "",
                "identificador": data[0]?.identificador || "",
                "folio": data[0]?.folio || "",
                "guid": data[0]?.guid || "",
                "padre": data[0]?.padre || "",
                "madre": data[0]?.madre || "",
                "sexo": data[0]?.sexo || "",
                "pais": data[0]?.pais || "",
                "curp": data[0]?.curp || "",
                "registrado": data[0]?.registrado || "",
                "primer_AP_MADRE": data[0]?.primer_AP_MADRE || "",
                "segundo_AP_MADRE": data[0]?.segundo_AP_MADRE || "",
                "primer_AP_PADRE": data[0]?.primer_AP_PADRE || "",
                "segundo_AP_PADRE": data[0]?.segundo_AP_PADRE || "",
                "edad_PADRE": data[0]?.edad_PADRE || "",
                "nacion_PADRE": data[0]?.nacion_PADRE || "",
                "edad_MADRE": data[0]?.edad_MADRE || "",
                "nacion_MADRE": data[0]?.nacion_MADRE || "",
                "estado": data[0]?.estado || "",
                "presentado": data[0]?.presentado || "",
                "actas_TEMP_ID": data[0]?.actas_TEMP_ID || "",
                "foja": data[0]?.foja || "",
              },
              "referencia": `${calculoData.referencias[0].referencia}`,
            },
            authToken: authToken,
            userId: user.sub,
            type: "servicios",
            "validationRequested": true,
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Manejar el error según tus necesidades
        console.error("Error en la transacción:", errorData.error);
        setLoadingPago(false);
        return;
      }

      const responseData = await response.json();
      // Manejar la respuesta según tus necesidades
      console.log("Respuesta de la transacción:", responseData);
      //PORQUE AQUI NO IRIA LO DE LOS TIPOS DE PAGO
      return responseData;
    } catch (error) {
      setLoadingPago(false)
      console.error("Error al realizar la transacción:", error.message);
      return error;
    }
  };

  const GenerarLigaPago = async (tipoPago = null) => {
    const user = await fetchUserAttributes();
    // metodoPago : pago1 : card - pago2 : - pago3 :
    // prepara conceptos con lo de calculo
    const opcionPago = tipoPago ? tipoPago : metodoPago;
    //preparar otras referencias
    const conceptos = calculoData.concepto?.map((cp) => {
      return {
        clave: cp.concepto,
        concepto: cp.descripcion,
        importe: cp.monto,
      };
    });
    const otrasRef = calculoData?.referencias?.map((referencia) => {

      const partes = referencia.recaudacion.split("-");
      const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
      const convenio = parteDespuesDelGuion;
      return {
        banco: referencia.recaudacion,
        convenio: convenio ? convenio : "",
        referencia: referencia.referencia,
      };
    });

    if (
      opcionPago === "pago1" ||
      opcionPago === "pago2" ||
      opcionPago === "pago3" ||
      opcionPago === "pago4"
    ) {
      generarTransaccion();
    }
    const variables = opcionPago === "pago1" ?
      {
        "id": idForm,
        "payload": {
          "paymentMethod": "evo",
          "email": user.email,
          "user": user.name !== undefined ? user.name : "Contribuyente",
          "tramite": "ACTA DE NACIMIENTO",
          "reference": `${calculo?.referencias[0].referencia}`, //"202300011471739766260",
          "monto": `${calculo?.importe}`, //"0.01",
          authToken,
          // taskToken,
          type: "servicios",
        },
      }
      : opcionPago === "pago2" ||
        opcionPago === "pago3" ||
        opcionPago === "pago4"
        ? {
          "id": idForm,
          "payload": {
            "paymentMethod": "spei",
            "data": {
              "user":
                user.name !== undefined
                  ? user.name
                  : "Contribuyente",
              "loteId": `${calculo?.loteId}`,
              "total_pagar": calculo?.importe,
              "tramite": "Acta de Nacimiento",
              "movimiento": "Copia Acta de Nacimiento",
              "fecha_generacion": getCurrentDate(),
              "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`, //"19/10/2022",
              "contribuyente": `${data[0]?.nombre} ${data[0]?.primer_AP} ${data[0]?.segundo_AP || ""}`,
              "curp": `${data[0]?.curp || ""}`,
              "referencia": `${calculo?.referencias[0].referencia}`,
              "otras_referencias": otrasRef,
            },
            "email": user.email,
            "procedure": "referencia_pago_servicios",
          },
        }
        : opcionPago === "Recibo"
          ? {
            "id": idForm,
            "payload": {
              "data": {
                user: user.name !== undefined ? user.name : "Contribuyente",
                "num_transaccion": calculoData.loteId,
                "tramite": "Acta de Nacimiento",
                "movimiento": "Acta de Nacimiento",
                "contribuyente": `${data[0]?.nombre} ${data[0]?.primer_AP} ${data[0]?.segundo_AP || ""}`,
                "fecha_pago": getCurrentDate(),
                // "rfc": `${calculoData.concepto.rfc}`,
                "otros_conceptos": conceptos,
                "total": `${calculoData.importe}`,
                "serie": `${calculoData.serie}`,
                "identificador": `${calculoData.identificador}`,
                "referencia": `${calculoData.referencias[0].referencia}`,
                "loteId": `${calculoData.loteId}`,
                "estatus": "PAGADO",
              },

              "email": user.email,
              "procedure": "recibo_pago_servicios",
              validationRequested: true,
              userId: user.sub,
              authToken,
            },
          }
          : {
            "id": idForm,
            "payload": {
              "paymentMethod": "spei",
              "data": {
                "user": user.name !== undefined ? user.name : "Contribuyente",
                "contribuyente": `${data[0]?.nombre} ${data[0]?.primer_AP} ${data[0]?.segundo_AP || ""}`,
                // "rfc": `${calculo?.concepto.rfc}`,
                "tramite": "Acta de Nacimiento",
                "movimiento": "Copia Acta de Nacimiento",
                "fecha_generacion": getCurrentDate(),
                "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`, //"19/10/2022",
                "otros_conceptos": conceptos,
                "total_pagar": `${calculo?.importe}`,
              },
              "email": user.email,
              "procedure": "presupuesto_servicios",
            },
          };
    // eslint-disable-next-line no-unused-vars
    const Data = await client.graphql({
      query: publishMessage,
      variables: {
        ...variables,
        payload: JSON.stringify(variables.payload),
      },
    });
    console.log("------------", Data);
  };
  useEffect(() => {
    const subscription = client.graphql({
      query: onMessageReceived,
      variables: { id: idForm },
    }).subscribe({
      next: (event) => {
        const receivedMessage = event.data.onMessageReceived;
        const dataRec = JSON.parse(receivedMessage.payload);
        console.log("DATA rec", dataRec);

        if (dataRec?.error) {
          setErrorMessage(dataRec?.error);
          setPagoFallidoModal(true);

          //alert("Hubo un error intentalo de nuevo")
          return;
        }
        if (dataRec.status === "PAGO VALIDADO") {
          //activar loader
          console.log("Validar pago");
          setRespuestaPago(dataRec);
          setLoadingPago(true);
        }
        if (dataRec?.procedure === "paymentUrl") {
          if (dataRec.response.cd_response === "error") {
            //AlertsAction("error")
            setPagoExitoModal(false);
            setPagoFallidoModal(true);
            setLoadingPago(false);
            setLoading(false);
            return;
          }
          if (dataRec.response.cd_response === "success") {
            //AlertsAction("success")
            // setPagoExitoModal(true);
            setPagoFallidoModal(false);
            /* setLigaPagoMit(dataRec.response.nb_url); */
            setSessionId(dataRec.response.session.sessionId)
            setVersionId(dataRec.response.session.version)
            setLoadingPago(false);
            setLoading(false);
          }
        }
        if (
          dataRec.Procedure === "referencia_pago_servicios" ||
          dataRec.Procedure === "presupuesto_servicios" ||
          dataRec.Procedure === "recibo_pago_servicios"
        ) {
          if (
            dataRec.Procedure === "referencia_pago_servicios" ||
            dataRec.Procedure === "presupuesto_servicios"
          ) {
            window.open(dataRec.TXTBody);
            setLoadingPago(false);
          }
          if (dataRec.Procedure === "recibo_pago_servicios") {
            //activar vista de exito
            setPantallaExito(true);
            setRespuestaRecibo(dataRec);
            setLoadingPago(false);
            setLoading(false);
            return;
          }
          if (dataRec.Procedure === "referencia_pago_servicios") {
            setLoadingPago(false);
            setLoading(false);
            setPagoExitoModal(true);
            setPagoFallidoModal(false);
            return;
          } /* else {
                  setCallFinal(true)
                }*/
          return;
        }
      },
      error: (error) => {
        console.error("Subscription error", error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [idForm]);

  const handleBlur = (field) => {
    formik.setFieldTouched(field, true);
  };
  const handleChangeAc = (panel) => (event, newExpanded) => {
    setExpanded(panel === "panel1" && !expanded);
};
  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Copia Certificada de Acta de Nacimiento</View>
        <View className="FormDescripcion"></View>
      </div>

      <View className="ViewPrincipal">
        {pantallaExito ? (
          <PantallaExitoImpuestos
            pago={respuestaPago}
            recibo={respuestaRecibo}
            titulo={
              "Actas de Nacimiento"
            }
          />
        ) : sessionId ? (
          <View>
            <div className="w-full flex justify-end">
              <img
                width={"300px"}
                height={"150px"}
                src={imagenTarjeta}
                alt="Mensaje tarjeta"
              />
            </div>
            <FormEvo sessionId={sessionId} versionId={versionId}/>
           {/*  <Iframe
              src={ligaPagoMit}
              height="850px"
              width={isMobile ? "99%" : "550px"}
              display="initial"
              styles={{ overflow: "hidden" }}
              position="relative"
            /> */}
          </View>
        ) : (
          <View className="FormContent">
            <Form
              onSubmit={formik.handleSubmit}
              noValidate
              className="ViewActa"
            >
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <></>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 ? (
                      <Box>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="validationFormik102"
                            className="position-relative"
                          >
                            <Form.Label>Primer Apellido*</Form.Label>
                            <Form.Control
                              type="text"
                              name="primerApellido"
                              value={formik.values.primerApellido}
                              onChange={(e) => formik.setFieldValue("primerApellido", e.target.value.toUpperCase())} // Convertir valor a mayúsculas
                              isValid={formik.touched.primerApellido && !formik.errors.primerApellido}
                              isInvalid={formik.touched.primerApellido && !!formik.errors.primerApellido}
                              disabled={loading}
                              onBlur={() => {
                                handleBlur('primerApellido'); consultaEndpoint();
                                // calcularServicioSei();
                              }}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.primerApellido}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Segundo Apellido</Form.Label>
                            <Form.Control
                              type="text"
                              name="segundoApellido"
                              value={formik.values.segundoApellido}
                              onChange={(e) => formik.setFieldValue("segundoApellido", e.target.value.toUpperCase())} // Convertir valor a mayúsculas
                              isValid={formik.touched.segundoApellido && !formik.errors.segundoApellido}
                              isInvalid={formik.touched.segundoApellido && !!formik.errors.segundoApellido}
                              disabled={loading}
                              onBlur={() => {
                                handleBlur(); consultaEndpoint();
                                // calcularServicioSei();
                              }}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.segundoApellido}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Nombre (s)*</Form.Label>
                            <Form.Control
                              type="text"
                              name="nombre"
                              value={formik.values.nombre}
                              onChange={(e) => formik.setFieldValue("nombre", e.target.value.toUpperCase())} // Convertir valor a mayúsculas
                              isValid={formik.touched.nombre && !formik.errors.nombre}
                              isInvalid={formik.touched.nombre && !!formik.errors.nombre}
                              disabled={loading}
                              onBlur={() => {
                                handleBlur("nombre"); consultaEndpoint();
                                // calcularServicioSei();
                              }}
                            />

                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.nombre}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="validationFormik102"
                            className="position-relative"
                          >
                            <Form.Label>Sexo*</Form.Label>
                            <Form.Select
                              name="sexo"
                              value={formik.values.sexo}
                              onChange={formik.handleChange}
                              disabled={loading}
                              isValid={
                                formik.touched.sexo && !formik.errors.sexo
                              }
                              isInvalid={
                                !!formik.errors.sexo && formik.touched.sexo
                              }
                              onBlur={() => {
                                handleBlur("sexo");
                                consultaEndpoint();
                                // calcularServicioSei();
                              }}
                            >
                              <option value="">Seleccione...</option>
                              <option value="F">Femenino</option>
                              <option value="M">Masculino</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.sexo}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Fecha de Nacimiento*</Form.Label>

                            <Form.Control
                              type="text"
                              name="fechaNacimiento"
                              placeholder="DD/MM/AAAA"
                              maxLength={10}
                              value={formik.values.fechaNacimiento}
                              onChange={handleDateChange}
                              isValid={
                                formik.touched.fechaNacimiento &&
                                !formik.errors.fechaNacimiento
                              }
                              isInvalid={
                                formik.touched.fechaNacimiento &&
                                !!formik.errors.fechaNacimiento
                              }
                              disabled={loading}
                              onBlur={() => {
                                handleBlur("fechaNacimiento");
                                consultaEndpoint();
                                // calcularServicioSei();
                              }}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.fechaNacimiento}
                            </Form.Control.Feedback>
                          </Form.Group>

                          {loading ? (

                            <div >
                              <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                            </div>

                          ) : null}
                        </Row>
                        <Row className="mb-3">
                          <Form.Label>Ingresar los datos del padre, madre o de la persona que te registró</Form.Label>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group className="position-relative">
                            <Form.Label>Primer Apellido*</Form.Label>
                            <Form.Control
                              type="text"
                              name="primerApellidoRegistro"
                              value={formik.values.primerApellidoRegistro}
                              onChange={(e) => formik.setFieldValue("primerApellidoRegistro", e.target.value.toUpperCase())}
                              isValid={formik.touched.primerApellidoRegistro && !formik.errors.primerApellidoRegistro}
                              onBlur={() => {
                                consultaEndpoint()
                                handleBlur("primerApellidoRegistro")}}
                              isInvalid={formik.touched.primerApellidoRegistro && !!formik.errors.primerApellidoRegistro}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.primerApellidoRegistro}</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group className="position-relative">
                            <Form.Label>Segundo Apellido</Form.Label>
                            <Form.Control
                              name="segundoApellidoRegistro"
                              type="text"
                              value={formik.values.segundoApellidoRegistro}
                              onChange={(e) => formik.setFieldValue("segundoApellidoRegistro", e.target.value.toUpperCase())}
                              onBlur={() => {
                                consultaEndpoint()
                                handleBlur("segundoApellidoRegistro")}}
                              isValid={formik.touched.segundoApellidoRegistro && !formik.errors.segundoApellidoRegistro}
                              isInvalid={formik.touched.segundoApellidoRegistro && !!formik.errors.segundoApellidoRegistro}

                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.segundoApellidoRegistro}</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group className="position-relative">
                            <Form.Label>Nombre (s)*</Form.Label>
                            <Form.Control
                              type="text"
                              name="nombreRegistro"
                              value={formik.values.nombreRegistro}
                              onChange={(e) => formik.setFieldValue("nombreRegistro", e.target.value.toUpperCase())}
                              isValid={formik.touched.nombreRegistro && !formik.errors.nombreRegistro}
                              isInvalid={formik.touched.nombreRegistro && !!formik.errors.nombreRegistro}
                              onBlur={() => {
                                consultaEndpoint()
                                handleBlur("nombreRegistro")}}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>{formik.errors.nombreRegistro}</Form.Control.Feedback>
                          </Form.Group>

                        </Row>
                        {data !== null && data?.length !== 0 ? (

                          <Row
                            className="mb-3"
                            style={{
                              width: "99%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: "auto",
                            }}
                          >
                            <Accordion
                              expanded={expanded}
                              onChange={handleChangeAc("panel1")}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div>
                                  <span className="text-[#a0aec0] text-sm font-semibold">
                                    Resultado del Acta de Nacimiento
                                  </span>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="">
                                  <span className=" text-2xl">
                                    Datos del acta de nacimiento
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Curp: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.curp || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Entidad de registro: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.estado_OFI || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Municipio de registro: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.munic_OFI || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Fecha de registro: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.registrado || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Libro: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.libro || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <div>Número de acta: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.acta || ""}</div>
                                  </div>
                                  <div></div>
                                  <span className=" text-2xl">
                                    Datos de la persona registrada
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Nombre(s): </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.nombre || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Primer Apellido: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.primer_AP || ""}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Segundo Apellido: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>
                                      {data[0]?.segundo_AP || ""}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Sexo: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.sexo || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Fecha de Nacimiento: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.fecha_NAC || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <div>Lugar de Nacimiento: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>
                                      {data[0] ? ((data[0].municipio || "") + " , " + (data[0].estado || "")).trim() || "" : ""}
                                    </div>
                                  </div>
                                  <span className=" text-2xl">
                                    Datos de la filiación de la persona registrada
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Nombre(s): </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.padre || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Primer apellido: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.primer_AP_PADRE || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Segundo apellido: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.segundo_AP_PADRE || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Nacionalidad: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.nacion_PADRE || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Nombre(s): </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.madre || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Primer apellido: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.primer_AP_MADRE || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Segundo apellido: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.segundo_AP_MADRE || ""}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",

                                    }}
                                  >
                                    <div>Nacionalidad: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }} >
                                      {data[0]?.nacion_MADRE || ""}
                                    </div>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Row>
                        ) : null}

                      </Box>
                    ) : (
                      activeStep === 1 && (
                        <FormPagos
                          metodoPago={metodoPago}
                          setMetodoPago={setMetodoPago}
                          generarPresupuesto={() => {
                            GenerarLigaPago("presupuesto");
                            /*   setTipoAccion(tipo.PRESUPUESTO) */
                          }}
                          continuarPago={setContinuarPago}
                        />
                      )
                    )}
                  </React.Fragment>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    variant="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Anterior
                  </ButtonB>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    onClick={() => {
                      /*  setTipoAccion(tipo.PRESUPUESTO) */
                      setLoadingPago(true);
                      GenerarLigaPago("presupuesto");
                    }}
                    variant="secondary"
                    className="mr-3"
                  >
                    Descargar Presupuesto
                  </ButtonB>
                )}
               <ButtonB  onClick={async () => {
                    if (activeStep === steps.length - 1) {
                      if (metodoPago === "pago1") {
                        GenerarLigaPago("pago1");
                        setLoadingPago(true);
                      } else {
                        GenerarLigaPago("pago2");
                        setLoadingPago(true);
                      }
                      return;
                    }
                    if (
                      formik.values.nombre === "" ||
                      formik.values.primerApellido === "" ||

                      formik.values.sexo === "" ||
                      formik.values.fechaNacimiento === "" ||
                      (data.length === 0) ||
                      (calculoData.concepto.length === 0)


                    ) {
                      // Si los campos están vacíos, no continuar
                      return;
                    }
                    await handleNext();
                    // calcularServicioSei();
                  }}
                  variant="secondary"
                  disabled={
                    loading ||
                    loadingCalculo ||
                    !formik.isValid ||
                    formik.values.nombre === "" ||
                    formik.values.primerApellido === "" ||

                    formik.values.sexo === "" ||
                    formik.values.fechaNacimiento === "" ||

                    (activeStep === steps.length - 1 && continuarPago === false)
                  }
                >
                  {activeStep === steps.length - 1
                    ? metodoPago === "pago1"
                      ? "Continuar al pago"
                      : "Descargar referencia de pago"
                    : calculoData?.importe !== 0
                      ? "Siguiente"
                      : "Realizar pago"}
                </ButtonB>
              </Box>
            </Form>
          </View>
        )}
      </View>
      <div>
        {loadingCalculo ? (

          <div >
            <Loader size='x-small' emptyColor="lightgray" filledColor="gray" variation='linear' />
          </div>

        ) : null}
        <ResumenCard tipo="actas" ligaPagoMit={ligaPagoMit} idForm={idForm}/>
      </div>
      <ModalActions
        title={"Los datos no existen"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        show={datoVacio}
        onHide={() => {
          setDatoVacio(false);
        }}
        buttonAction={() => {
          setDatoVacio(false);
        }}
      />
      <ModalActions
        title={"Se ha generado la referencia de pago con éxito"}
        content={""}
        icon={"check"}
        show={pagoExitoModal}
        onHide={() => {
          setPagoExitoModal(false);
        }}
        buttonAction={() => {
          setPagoExitoModal(false);
        }}
      />
      <ModalActions
        title={"Hubo un problema"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        //desc={errorMessage}
        show={pagoFallidoModal}
        onHide={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
        buttonAction={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
      />

      <ModalActions
        title={"Advertencia"}
        content={
          "Alcanzaste el limite de declaraciones permitidas en el periodo seleccionado."
        }
        icon={"error"}
        show={decLimiteAlcanzado}
        onHide={() => {
          setDecLimiteAlcanzado(false);
        }}
        buttonAction={() => {
          setDecLimiteAlcanzado(false);
        }}
      />

      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}
      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
