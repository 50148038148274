const ENDPOINT_SEI = "https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev"

const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;

export function ObtenerToken() {
    const url = `${ENDPOINT}/auth`
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        }
    };

    return fetch(url, params).then(response => {
        return response.json()
    }).then(result => {

        if (result.error === undefined) {

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        } else {
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}

export function CrearIteamAPI(data) {
    const url = `${ENDPOINT_SEI}/procedures/upsert`
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    };
    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {

        if (result.error === undefined) {

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        } else {
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: err.message
        };
    })
}


export async function ArrayOpciones(params = {}) {
    const { id, valor = "dominioId" } = params;
    const url = `${ENDPOINT_SEI}/catalog/queryCategoryItems`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        [valor]: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(url, requestOptions);
        const result = await response.json();
        if (Array.isArray(result.items)) {
            return result.items;
        } else {
            console.error("result.items is not an array:", result.items);
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}