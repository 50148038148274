import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import { publishMessage } from '../../graphql/mutations';
import * as yup from 'yup';
import { View, } from "@aws-amplify/ui-react";
import { isMobile } from 'react-device-detect';
import { Button as ButtonB } from 'react-bootstrap';
import ResumenCard from './ResumenCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { toast, ToastContainer } from 'react-toastify';
import ModalActions from './ModalActions';
import { onMessageReceived } from '../../graphql/subscriptions';
import FormPagos from '../FormPagos';
import { useTramiteDataGeneral } from '../../TramiteContext'
import { Loader } from "@aws-amplify/ui-react";
import { ObtenerToken } from '../../api/Impuestos';
import Iframe from 'react-iframe';
import { Backdrop, CircularProgress } from '@mui/material';
import { VerificarClaveAPI, CalcularPredialAPI } from '../../api/Predial';
import imagenTarjeta from '../../assets/mensaje_tarjeta.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
import FormEvo from '../FormEvo';
const steps = ['Información', 'Formas de pago'];
const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT;
/* const tipo = {
    PRESUPUESTO: 'presupuesto',
    RECIBO: 'Recibo',
    PRE: 'precalculo',
    REFERENCIA: 'pago2',
    PAGO: 'pago1'
} */


    const client = generateClient();
export const FormTramitePredial = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [activeStep, setActiveStep] = useState(0);
    const [dataPredial, setDataPredial] = useState("");
    const [token, setToken] = useState("");
/*     const [tipoAccion, setTipoAccion] = useState(tipo.PRE)
 */    const { data, setCalculo, setData, calculo } = useTramiteDataGeneral()
    const [idForm, setIdForm] = useState('Not Assign');
    const [skipped, setSkipped] = React.useState(new Set());
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [pagoExitoModal, setPagoExitoModal] = useState(false)
    const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingPago, setLoadingPago] = useState(false);
    const [respuestaPago, setRespuestaPago] = useState({});
    const [pantallaExito, setPantallaExito] = useState(false)
    const [respuestaRecibo, setRespuestaRecibo] = useState({})
    const [decLimiteAlcanzado, setDecLimiteAlcanzado] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [ligaPagoMit, setLigaPagoMit] = useState(null)
    const [continuarPago, setContinuarPago] = useState(false)
    const [expanded, setExpanded] = React.useState(true);
    const [sessionId, setSessionId] = useState(null);
    const [versionId, setVersionId] = useState(null);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(panel === "panel1" && !expanded);
    };
    const validationSchema = yup.object().shape({
        claveCatastral: yup.string().required("Este Campo es Obligatorio")
    });
 
   
    const generateID = async () => {
        const user = await getCurrentUser();
        const id = `InscImpuestoPredial_${user.username}_${Date.now()}`
        setIdForm(id)
        return id
    }
    useEffect(() => {
        ObtenerToken().then(result => {
            if (result.ok === true) {
                setToken(result.message.token)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }, []);

const eliminarGuiones=(clave)=>{
    return clave.replace(/-/g, "")
}

    const VerificarClave = (clave) => {
        if (clave !== "") {
            let claveSinGuiones = eliminarGuiones(clave)
            setLoading(true);

            VerificarClaveAPI(claveSinGuiones, token).then(result => {
                console.log(result);
                if (result.message.mensaje !== undefined) {
                    toast.error(result.message.mensaje, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setData(null)
                    setCalculo(null);
                    setLoading(false);
                }else if(result.ok === false){
                    toast.error(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setData(null)
                    setCalculo(null);
                    setLoading(false);

                } else {
                    setData(result.message[0])
                    setLoading(false);
                    setIsLoading(false);
                    CalcularPredial(result.message[0]);
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }
    
    const handleNext = async () => {
        await generateID(); 
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            handleNext()
        },
        initialValues: {
            claveCatastral: '',
        }
    });
    const CalcularPredial = (data) => {
        setLoadingPago(true);
        CalcularPredialAPI(data, token).then(result => {
            console.log(result);
            if (result.ok) {
                
                setCalculo(result.message);
                setLoadingPago(false);
            } else {
                setLoadingPago(false);
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            setLoadingPago(false);
            console.log(error)
        })
    }
    const formatearFecha = (fecha) => {
        if (fecha) {
            const date = new Date(fecha);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return null;
    };
    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
        const year = today.getFullYear(); // Get the full year (4 digits)

        return `${day}/${month}/${year}`;
    }
    const handleInputChangeNumero = (e) => {
        const { name, value } = e.target;
        // eslint-disable-next-line no-useless-escape
        const numericValue = value.replace(/[^\d\-]/g, "");
        formik.setFieldValue(name, numericValue);

    };

    const generarTransaccion = async () => {
        console.log("LLAMADDO a tarns");
        console.log(idForm);
        const user = await fetchUserAttributes();
        try {
            const transaccionEndpoint = `${ENDPOINT_SEI}/sei/putEvent`;

            const conceptos = calculo.concepto?.map(cp => {
                return { clave: cp.concepto, concepto: cp.descripcion, importe: cp.monto }
            })
            const response = await fetch(transaccionEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: idForm,
                    payload: {
                        "validationRequested": true,

                        "data": {
                            "email": user.email,
                            "padron": {
                                "nombrePadron": "IMPUESTO PREDIAL"
                            },
                            "user": user.name !== undefined ? user.name : "Contribuyente",
                            "contribuyente": data?.propietario,
                            "estadoCuenta": {
                                "serie": calculo?.serie,
                                "identificador": calculo?.identificador,
                                "tramiteId": calculo?.tramiteId,
                                "loteId": calculo?.loteId,
                                "fechaVencimiento": calculo?.fechaVencimiento,
                                "total": calculo?.importe // -- cambio
                            },
                            "conceptos": conceptos,
                            "domicilio": dataPredial?.domicilio,
                            "propietario": dataPredial.propietario,
                            "localidad": dataPredial.localidad,
                            "municipio": dataPredial.municipio,
                            "referencia": `${calculo?.referencias[0].referencia}`,
                            "catastral": formik.values.claveCatastral,
                            "catastralAnterior": ""
                        },
                        authToken: token,
                        userId: user.sub,
                        type: "predial",

                    }

                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                // Manejar el error según tus necesidades
                console.error("Error en la transacción:", errorData.error);
                return;
            }

            const responseData = await response.json();
            // Manejar la respuesta según tus necesidades
            console.log("Respuesta de la transacción:", responseData);

            return responseData
        } catch (error) {

            console.error("Error al realizar la transacción:", error.message);
            return error
        }
    };





    const GenerarLigaPago = async (tipoPago = null) => {

        const user = await fetchUserAttributes();
        // metodoPago : pago1 : card - pago2 : - pago3 :
        // prepara conceptos con lo de calculo
        const opcionPago = tipoPago ? tipoPago : metodoPago
        //preparar otras referencias
        const conceptos = calculo.concepto?.map(cp => {
            return { clave: cp.concepto, concepto: cp.descripcion, importe: cp.monto }
        })
        const otrasRef = calculo?.referencias?.map(referencia => {
            const partes = referencia.recaudacion.split('-');
            const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
            const convenio = parteDespuesDelGuion
            return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
        })

        if (opcionPago === "pago1" || opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4") {
            generarTransaccion()
        }
        const variables = opcionPago === "pago1" ?
            {
                "id": idForm,
                "payload": {
                    "paymentMethod": "evo",
                    "email": user.email,
                    "user": user.name !== undefined ? user.name : "Contribuyente",
                    "tramite": "Impuesto Predial",
                    "reference": `${calculo?.referencias[0].referencia}`,//"202300011471739766260",
                    "monto": `${calculo?.importe}`,//"0.01",
                    "type": "predial",
                }
            }
            :
            opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4" ?
                {
                    "id": idForm,
                    "payload": {

                        "paymentMethod": "spei",
                        "data": {
                            "user": user.name !== undefined ? user.name : "Contribuyente",
                            "loteId": `${calculo?.loteId}`,
                            "total_pagar": calculo?.importe,
                            "tramite": "Impuesto Predial",//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                            "movimiento": "Impuesto Predial",
                            "fecha_generacion": getCurrentDate(),
                            "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`,//"19/10/2022",
                            "catastral": formik.values?.claveCatastral,
                            "contribuyente": data?.propietario,
                            "referencia": `${calculo?.referencias[0].referencia}`,
                            "otras_referencias": otrasRef,

                        },
                        "email": user.email,
                        "procedure": "referencia_pago_predial"
                    }
                }
                : opcionPago === "Recibo" ?
                    {
                        "id": idForm,
                        "payload": {
                            "data": {
                                user: user.name !== undefined ? user.name : "Contribuyente",
                                email: user.email,
                                estadoCuenta: {
                                    serie: calculo.estadoCuenta.serie,
                                    identificador: calculo.estadoCuenta.identificador,
                                    loteId: calculo.estadoCuenta.loteId,
                                    tramiteId: calculo.estadoCuenta.tramiteId,
                                    fechaVencimiento: calculo.estadoCuenta.fechaVencimiento
                                },
                                serie: calculo?.serie,
                                "docData": {
                                    "loteId": `${calculo?.loteId}`,
                                    "identificador": `${calculo?.identificador}`,
                                    "serie": `${calculo?.serie}`,
                                    "contribuyente": data?.propietario,
                                    "fecha_generacion": getCurrentDate(),
                                    "fecha_pago": getCurrentDate(),
                                    "estatus": "PAGADO",
                                    "saldo_favor":calculo?.saldo_favor,
                                    //"num_transaccion": calculoData.estadoCuenta?.loteId,
                                    "referencia": `${calculo?.referencias[0].referencia}`,
                                    "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`,//"19/10/2022",
                                    "movimiento": "IMPUESTO PREDIAL",
                                    "tramite": "IMPUESTO PREDIAL",//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                                    "tramiteId": calculo?.tramiteId,
                                    "instrumento_pago": "referencia bancaria",
                                    "total": `${calculo?.importe}`,

                                }
                            },
                            "email": user.email,
                            "procedure": "recibo_pago_predial",
                            "validationRequested": true,
                            "type": "predial",
                            userId: user.sub,

                        },
                    }
                    :
                    {
                        "id": idForm,
                        "payload": {
                            "paymentMethod": "spei",
                            "data": {
                                "user": user.name !== undefined ? user.name : "Contribuyente",
                                "contribuyente": data?.propietario,
                                "domicilio": data?.domicilio,
                                "catastral": formik.values.claveCatastral,
                                "municipio": data?.municipio,
                                "localidad": data?.localidad,
                                "fecha_generacion": getCurrentDate(),
                                "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`,//"19/10/2022",
                                "movimiento": "IMPUESTO PREDIAL",
                                "tramite": "IMPUESTO PREDIAL",//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                                "instrumento_pago": "referencia bancaria",
                                "otros_conceptos": conceptos,
                                "total_pagar": `${calculo?.importe}`,
                            },
                            "email": user.email,
                            "procedure": "presupuesto_predial"

                        },
                    };
        // eslint-disable-next-line no-unused-vars
        const Data = await client.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
       



    }
    useEffect(() => {
        const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
            .subscribe({
                next: (event) => {
                    const receivedMessage = event.data.onMessageReceived;
                    //parse json on payload response. - authToken - taskToken --- rfc
                    /*      setMessage(receivedMessage); */
                    const dataRec = JSON.parse(receivedMessage.payload)
                    console.log("DATA rec", dataRec);
                    /* if (dataRec.taskToken) {
                      setTaskToken(dataRec.taskToken)
                    } */
                    if (dataRec?.error) {
                        setErrorMessage(dataRec?.error)
                        setPagoFallidoModal(true)

                        //alert("Hubo un error intentalo de nuevo")
                        return
                    }
                    if (dataRec.status === "PAGO VALIDADO") {
                        //activar loader
                        console.log("Validar pago");
                        setRespuestaPago(dataRec)
                        setLoadingPago(true)

                    }
                    if (dataRec?.procedure === "paymentUrl") {
                        if (dataRec.response.cd_response === "error") {
                            //AlertsAction("error")
                            setPagoExitoModal(false)
                            setPagoFallidoModal(true)
                            setLoadingPago(false);
                            setLoading(false)
                            return
                        }
                        if (dataRec.response.cd_response === "success") {
                            //AlertsAction("success")
                            // setPagoExitoModal(true);
                            setPagoFallidoModal(false);
                            /* setLigaPagoMit(dataRec.response.nb_url); */
                            setSessionId(dataRec.response.session.sessionId)
                            setVersionId(dataRec.response.session.version)
                            setLoadingPago(false);
                            setLoading(false);
                          }
                    }
                    if (dataRec.Procedure === "referencia_pago_predial" || dataRec.Procedure === "presupuesto_predial" || dataRec.Procedure === "recibo_pago_predial") {
                        if (dataRec.Procedure === "referencia_pago_predial" || dataRec.Procedure === "presupuesto_predial") {
                            window.open(dataRec.TXTBody);
                            setLoadingPago(false)
                        }
                        if (dataRec.Procedure === "recibo_pago_predial") {
                            //activar vista de exito
                            setPantallaExito(true)
                            setRespuestaRecibo(dataRec)
                            setLoadingPago(false)
                            setLoading(false)
                            return
                        }
                        if (dataRec.Procedure === "referencia_pago_predial") {
                            setLoadingPago(false)
                            setLoading(false)
                            setPagoExitoModal(true)
                            setPagoFallidoModal(false)
                            return
                        }/* else {
                  setCallFinal(true)
                }*/
                        return
                    }
                },
                error: (error) => {
                    console.error('Subscription error', error);
                },
            });


        return () => {
            subscription.unsubscribe();
        };
    }, [idForm]);
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Pago al Impuesto Predial</View>
                <View className='FormSubTitle'>--Impuesto Predial--</View>
                <View className='FormDescripcion'>
                    Este trámite consiste en el pago del impuesto que aplica sobre la propiedad inmueble y se genera por la existencia del predio. Tiene diferentes propósitos, entre ellos mejorar la calidad de vida de los ciudadanos con las condiciones de los servicios municipales.
                </View>
            </div>

            <View className='ViewPrincipal'>
                {
                    pantallaExito ?
                        <PantallaExitoImpuestos pago={respuestaPago} recibo={respuestaRecibo} titulo={'Impuesto Predial'} />
                        :
                        sessionId ?
                            <View >
                                <div className='w-full flex justify-end'><img width={'300px'} height={'150px'} src={imagenTarjeta} alt="Mensaje tarjeta" /></div>
                                <FormEvo sessionId={sessionId} versionId={versionId}/>
                              {/*   <Iframe
                                    src={ligaPagoMit}
                                    height="850px"
                                    width={isMobile ? "99%" : "550px"}
                                    display="initial"
                                    styles={{ overflow: "hidden" }}
                                    position="relative"
                                /> */}
                            </View>
                            :
                            <View className='FormContent'>
                                <Form
                                    onSubmit={formik.handleSubmit}
                                    noValidate
                                    className="ViewActa"
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <Stepper activeStep={activeStep}>
                                            {steps.map((label, index) => {
                                                const stepProps = {};
                                                const labelProps = {};


                                                return (
                                                    <Step key={label} {...stepProps}>
                                                        <StepLabel  {...labelProps}>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                        {activeStep === steps.length ? (
                                            <></>
                                        ) : (
                                            <React.Fragment>
                                                {activeStep === 0 ? (
                                                    <Box>
                                                        <Row className='mt-3'>
                                                            <Form.Group
                                                                as={Col}
                                                                controlId="validationFormik102"
                                                                className="position-relative"
                                                            >
                                                                <Form.Label >Clave Catastral*</Form.Label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="claveCatastral"
                                                                        maxLength={50}
                                                                        value={formik.values.claveCatastral}
                                                                        onChange={handleInputChangeNumero}
                                                                        onBlur={() => VerificarClave(formik.values.claveCatastral)}
                                                                        isValid={formik.touched.claveCatastral && !formik.errors.claveCatastral}
                                                                        isInvalid={!!formik.errors.claveCatastral && formik.touched.claveCatastral}
                                                                    />
                                                                    {loading ? (

                                                                        <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                            <Loader size='large' emptyColor="lightgray" filledColor="gray" />
                                                                        </div>

                                                                    ) : null}


                                                                </div>
                                                                <Form.Text muted>
                                                                    <ul style={{ padding: "0" }}>
                                                                        <li>NO ES NECESARIO COLOCAR LOS GUIONES EN LA CLAVE CATASTRAL</li>
                                                                        <li>Se puede realizar la búsqueda por medio de alguna de las 3 claves catastrales (anterior, actual o clave catastral).</li>
                                                                    </ul>
                                                                </Form.Text>

                                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.claveCatastral}</Form.Control.Feedback>
                                                            </Form.Group>

                                                        </Row>
                                                        {data &&
                                                            <Row className='mb-3'  style={{width:"99%", display:"flex", justifyContent:"center", alignItems:"center", margin:"auto"}}>
                                                                <Accordion expanded={expanded} onChange={handleChange('panel1')}  >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >

                                                                        <div>
                                                                            <span className='text-[#a0aec0] text-sm font-semibold'>DETALLES DE LA CLAVE CATASTRAL</span>

                                                                        </div>

                                                                    </AccordionSummary>
                                                                    <AccordionDetails>

                                                                        <div className=''>
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div  >Propietario:</div>
                                                                                <div >{data.propietario}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div  >Calle:</div>
                                                                                <div className=' '>{data.calle}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Código Postal:</div>
                                                                                <div className=' ' md='6'>{data.codigoPostal}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div  >Colonia:</div>
                                                                                <div className=' '>{data.colonia}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div  >Domicilio:</div>
                                                                                <div className=' '>{data.domicilio}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Número Exterior:</div>
                                                                                <div className=' ' md='6'>{data.numeroExterior}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Número Interior:</div>
                                                                                <div className=' ' md='6'>{data.numeroInterior}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Estado:</div>
                                                                                <div className=' ' md='6'>{data.estado}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Municipio:</div>
                                                                                <div className=' ' md='6'>{data.municipio}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Localidad:</div>
                                                                                <div className=' ' md='6'>{data.localidad}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Lote:</div>
                                                                                <div className=' ' md='6'>{data.lote}</div>
                                                                            </div >
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div md='6'>Manzana:</div>
                                                                                <div className=' ' md='6'>{data.manzana}</div>
                                                                            </div >

                                                                          
                                                                        </div>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Row>
                                                        }
                                                    </Box>
                                                ) : (
                                                    activeStep === 1 && (
                                                        <FormPagos
                                                            metodoPago={metodoPago}
                                                            setMetodoPago={setMetodoPago}
                                                            generarPresupuesto={() => {
                                                                GenerarLigaPago("presupuesto")
                                                                /*   setTipoAccion(tipo.PRESUPUESTO) */

                                                            }}
                                                            continuarPago={setContinuarPago}
                                                        />
                                                    )
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        {
                                            activeStep === steps.length - 1 &&
                                            <ButtonB
                                                variant='secondary'
                                                disabled={activeStep === 0}
                                                onClick={handleBack}


                                            >
                                                Anterior
                                            </ButtonB>
                                        }
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {
                                            activeStep === steps.length - 1 &&
                                            <ButtonB
                                                onClick={() => {
                                                    /*  setTipoAccion(tipo.PRESUPUESTO) */
                                                    setLoadingPago(true);
                                                    GenerarLigaPago("presupuesto")
                                                }}
                                                variant='secondary' className='mr-3'>
                                                Descargar Presupuesto
                                            </ButtonB>
                                        }
                                      <ButtonB  onClick={async () => {

                                            if (activeStep === steps.length - 1) {
                                                /*setTipoAccion(tipo.PRESUPUESTO)
                                                calcularServicioSei(true);
                                                return;*/
                                                if (metodoPago === "pago1") {
                                                    /* setTipoAccion(tipo.PAGO) */
                                                    GenerarLigaPago("pago1")

                                                    setLoadingPago(true);
                                                    //generarTransaccion(tipo.PAGO)
                                                } else {
                                                    /* setTipoAccion(tipo.REFERENCIA) */
                                                    GenerarLigaPago("pago2")

                                                    setLoadingPago(true);
                                                    //generarTransaccion(tipo.REFERENCIA)
                                                }

                                                return;
                                            }

                                            await formik.handleSubmit()
                                        }} variant='secondary'
                                            disabled={isLoading || calculo === null||(activeStep === steps.length - 1 && continuarPago === false)}
                                        >
                                            {activeStep === steps.length - 1 ? metodoPago === "pago1" ? 'Continuar al pago' : "Descargar referencia de pago" : calculo?.importe !== 0 ? 'Siguiente' : 'Siguiente'}
                                        </ButtonB>
                                    </Box>

                                </Form>

                            </View>
                }
            </View >
            <ResumenCard tipo="Predial" ligaPagoMit={ligaPagoMit} idForm={idForm}/>
            <ModalActions
                title={'Se ha generado la referencia de pago con éxito'}
                content={''} icon={'check'}
                show={pagoExitoModal}
                onHide={() => { setPagoExitoModal(false); }}
                buttonAction={() => { setPagoExitoModal(false); }}
            />
            <ModalActions
                title={'Hubo un problema'}
                content={'por favor, vuelve a intentarlo'} icon={'error'}
                desc={errorMessage}
                show={pagoFallidoModal}
                onHide={() => { setPagoFallidoModal(false); setErrorMessage('') }}
                buttonAction={() => { setPagoFallidoModal(false); setErrorMessage('') }}
            />

            <ModalActions
                title={'Advertencia'}
                content={'Alcanzaste el limite de declaraciones permitidas en el periodo seleccionado.'} icon={'error'}
                show={decLimiteAlcanzado}
                onHide={() => { setDecLimiteAlcanzado(false); }}
                buttonAction={() => { setDecLimiteAlcanzado(false); }}
            />


            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingPago}

            >
                <h1>Esperando Respuesta</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}