/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const receiveMessage = /* GraphQL */ `
  mutation ReceiveMessage(
    $id: ID!
    $payload: AWSJSON
    $updatedAt: AWSDateTime
  ) {
    receiveMessage(id: $id, payload: $payload, updatedAt: $updatedAt) {
      id
      payload
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const publishMessage = /* GraphQL */ `
  mutation PublishMessage(
    $id: ID!
    $payload: AWSJSON
    $updatedAt: AWSDateTime
  ) {
    publishMessage(id: $id, payload: $payload, updatedAt: $updatedAt) {
      id
      payload
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      payload
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      payload
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      payload
      updatedAt
      createdAt
      __typename
    }
  }
`;
