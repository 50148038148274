import React, { useEffect } from "react";

const FormEvo = (props) => {
    const { sessionId, versionId } = props;
    useEffect(() => {
        // Cargar el script externo dinámicamente
        const script = document.createElement("script");
        script.src =
            "https://evopaymentsmexico.gateway.mastercard.com/static/checkout/checkout.min.js";
        script.async = true;

        // Configurar las funciones de error y cancelación
        script.setAttribute("data-error", "errorCallback");
        script.setAttribute("data-cancel", "cancelCallback");

        document.body.appendChild(script);

        // Definir las funciones globales necesarias
        window.errorCallback = (error) => {
            console.log("Error:", JSON.stringify(error));
        };

        window.cancelCallback = () => {
            console.log("Payment cancelled");
        };
        // Configurar el objeto Checkout
        script.onload = () => {
            if (window.Checkout) {
                window.Checkout.configure({
                    session: {
                        id: sessionId,
                        /* version: versionId */
                    },
                });
                window.Checkout.showEmbeddedPage("#embed-target");
            }
        };

        return () => {
            // Limpiar el script y las funciones globales
            document.body.removeChild(script);
            delete window.errorCallback;
            delete window.cancelCallback;
        };
    }, []);

    // Función para mostrar la página embebida




    // Función para mostrar la página de pago


    return (
        <div>
            <div id="embed-target" style={{ border: "1px solid #ccc", padding: "20px", minHeight: "40rem", boxShadow:"4px 4px 8px rgba(0, 0, 0, 0.5)", borderRadius:"10px" }}>
                {/* Aquí se renderizará la página embebida */}
            </div>

        </div>
    );
};

export default FormEvo;
