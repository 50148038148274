import React, { useEffect, useState } from "react";
import Select from "react-select"; // Asegúrate de que estás importando Select
import {Form} from "react-bootstrap"

// Tu función asíncrona
const ObtenerDatosSelect = async (params = {}) => {
    const { id, valor = "dominioId" } = params;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        [valor]: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/catalog/queryCategoryItems", requestOptions);
        const result = await response.json();
        if (Array.isArray(result.items)) {
            result.items.sort(function (a, b) {
                return a.ID - b.ID;
            });
            return result.items;
        } else {
            console.error("result.items is not an array:", result.items);
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
};

const OpcionesSelect = (props) => {
    const { id, valor, setFieldValue, campo, name, nueva, onBlur, value, touched, errors, isDisabled,setFieldTouched, obtenerCodigosPostales } = props;
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await ObtenerDatosSelect({ id, valor });
            if (nueva === 232) {
                const idToRemove = 320;
                const newArray = data.filter(item => item.ID !== idToRemove);
                setItems(newArray);
            } else {
                setItems(data);
            }
            setFieldValue(name, campo); // Actualizas el valor del campo de Formik
        };

        fetchData();
    }, [id]); // Se vuelve a ejecutar cuando `id` cambia

    // Mapea los items obtenidos a un formato que el Select de react-select pueda utilizar
    const opciones = items.map(item => ({
        value: item.ID,
        label: id === "PAISES" ? item.DenominacionPais.toUpperCase() : item.DESCRIPCION
    }));

    return (
        <>
            <Select
                classNamePrefix="custom-select"
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                name={name}
                value={opciones.find(option => option.value === value) || null}
                onChange={option => {setFieldValue(name, option ? option.value : "")
                    if(name === "estado"){
                        obtenerCodigosPostales(option.value, setFieldValue, setFieldTouched)
                    }else if(name === "nacionalidad"){
                        if (option.value === 426) {
                            setFieldValue("estadoNacimiento", "")
                        } else {
                            setFieldValue("estadoNacimiento", 344)

                        }
                    }
                }}
                onBlur={onBlur}
                options={opciones}
                isSearchable={true}
                isDisabled={isDisabled}
                placeholder="Seleccione una opción"
                className={
                    touched && errors ? 'is-invalid' : touched ? 'is-valid' : ''
                }
            />
            {touched && errors && (
                 <Form.Control.Feedback type="invalid" tooltip>
                 {errors}
             </Form.Control.Feedback>
            )}
        </>
    );
};

export default OpcionesSelect;
