import { useEffect, useState } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    InputGroup,
    Accordion,
    Table,
    Modal,
} from "react-bootstrap";
import { View } from "@aws-amplify/ui-react";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Backdrop,
    CircularProgress,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { Stack, Pagination } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from "@mui/icons-material/Edit";
import Revisado from "../../assets/Revision.png";
import Bloqueado from "../../assets/Bloqueado.png";
import Inactivo from "../../assets/Inactivo.png";
import Rechazado from "../../assets/Rechazado.png";
import Aprobado from "../../assets/Aprobado.png";
import Pagado from '../../assets/Pagado.png'
import Captura from '../../assets/Captura.png'
import ListoPago from '../../assets/ListoParaPago.png'
import Asignacion from '../../assets/AsignaciondeFormas.png'
import ListoEnvio from '../../assets/ListoParaEnvio.png'
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ModalActions from "../Static_Forms/ModalActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import * as yup from "yup";
import { SiMicrosoftexcel } from "react-icons/si";
import Iframe from "react-iframe";
import Swal from "sweetalert2";
import Select from 'react-select';
import { FaArrowLeft } from "react-icons/fa";
import { ModalEditar } from "./ModalEditar";
import { ObtenerExcel } from "../../api/Flotillas";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR  ;
const ENDPOINT_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS ;
const estadosArray = [
    { value: "", label: "Estado Procedencia" },
    { value: 'AGUASCALIENTES', label: 'AGUASCALIENTES' },
    { value: 'BAJA CALIFORNIA', label: 'BAJA CALIFORNIA' },
    { value: 'BAJA CALIFORNIA SUR', label: 'BAJA CALIFORNIA SUR' },
    { value: 'CAMPECHE', label: 'CAMPECHE' },
    { value: 'COAHUILA', label: 'COAHUILA' },
    { value: 'COLIMA', label: 'COLIMA' },
    { value: 'CHIAPAS', label: 'CHIAPAS' },
    { value: 'CHIHUAHUA', label: 'CHIHUAHUA' },
    { value: 'DISTRITO FEDERAL', label: 'DISTRITO FEDERAL' },
    { value: 'GUANAJUATO', label: 'GUANAJUATO' },
    { value: 'GUERRERO', label: 'GUERRERO' },
    { value: 'HIDALGO', label: 'HIDALGO' },
    { value: 'JALISCO', label: 'JALISCO' },
    { value: 'MEXICO', label: 'MÉXICO' },
    { value: 'MICHOACAN', label: 'MICHOACÁN' },
    { value: 'MORELOS', label: 'MORELOS' },
    { value: 'NAYARIT', label: 'NAYARIT' },
    { value: 'NUEVO LEON', label: 'NUEVO LEÓN' },
    { value: 'OAXACA', label: 'OAXACA' },
    { value: 'PUEBLA', label: 'PUEBLA' },
    { value: 'QUERETARO', label: 'QUERÉTARO' },
    { value: 'QUINTANA ROO', label: 'QUINTANA ROO' },
    { value: 'SAN LUIS POTOSI', label: 'SAN LUIS POTOSÍ' },
    { value: 'SINALOA', label: 'SINALOA' },
    { value: 'SONORA', label: 'SONORA' },
    { value: 'TABASCO', label: 'TABASCO' },
    { value: 'TAMAULIPAS', label: 'TAMAULIPAS' },
    { value: 'TLAXCALA', label: 'TLAXCALA' },
    { value: 'VERACRUZ', label: 'VERACRUZ' },
    { value: 'YUCATAN', label: 'YUCATÁN' },
    { value: 'ZACATECAS', label: 'ZACATECAS' },
].map(opcion => ({ ...opcion, label: opcion.label }));
export const DetallesFlotillas = () => {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [dataTicket, setDataTicket] = useState(null);
    const [perData, setPerData] = useState(null);
    const [changeInfo, setChangeInfo] = useState(true);
    const [helpView, setHelpView] = useState(false);
    const [helpView2, setHelpView2] = useState(false);
    const navigate = useNavigate();
    const [showEdit, setShowEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [viewDocuent, setViewDocument] = useState(false);
    const [infoDocuent, setInfoDocument] = useState("");
    const [viewComment, setViewComment] = useState(false)
    const [comments, setComments] = useState(null)
    const [direccion, setDireccion] = useState("");
    const [showDireccion, setShowDireccion] = useState(false);
   
    function buscarURLPorNombre(objeto, nombreBuscado) {
        for (const key in objeto) {
            if (objeto[key].name === nombreBuscado) {
                return objeto[key].url;
            }
        }
        return null; // Retorna null si no se encuentra ninguna coincidencia
    }
    const handleDocumento = (url) => {
        setInfoDocument(url);
        setViewDocument(true);
    };
    const opcionesDeFormato = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,  // Cambiado a false para usar formato de 24 horas
        timeZone: 'UTC',  // Asegura que la hora se interpreta como UTC


    };

    const getIconStatus = (state) => {
        switch (state) {
            case 'Revisión':
                return <img src={Revisado} alt='Status' />;
            case 'Aprobado':
                return <img src={Aprobado} alt='Status' />;
            case 'Rechazado':
                return <img src={Rechazado} alt='Status' />;
            case 'Bloqueado':
                return <img src={Bloqueado} alt='Status' />;
            case 'Inactivo':
                return <img src={Inactivo} alt='Status' />;
            case 'Pagado':
                return <img src={Pagado} alt='Status' />;
            case 'Captura':
                return <img src={Captura} alt='Status' />
            case 'Generar presupuestación':
                return <img src={ListoPago} alt='Status' />
            case 'Asignación de formas':
                return <img src={Asignacion} alt='Status' />
            case 'Listo para envío':
                return <img src={ListoEnvio} alt='Status' />
            default:
                // You can return a default icon or handle the default case as needed
                return null;
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "flotillaID": location.state.id.S
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${ENDPOINT}/listDireccionByFlotilla`, requestOptions)
                .then(response => response.json())
                .then(result => {

                    if (result.Count !== 0) {

                        setDireccion(result.Items)
                    }
                })
                .catch(error => console.log('error', error));
        }
        fetchData()
    }, [])

    useEffect(() => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            usuarioID: location.state.usuarioID.S, //'0e5a5f82-7027-4278-aad1-a7791aa7fe38',
            flotillaID: location.state.id.S, //'76d69fd6-02ca-414a-a68c-4ca433170059',
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/listaByFlotilla`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                result.sort(function (a, b) {
                    return a.formularioID - b.formularioID;
                });
                setData(result); // Actualiza el estado con los datos recibidos
            })
            .catch((error) => console.log("error", error));


    }, [changeInfo]);

    useEffect(() => {
        const fetchData = async () => {
            const requestData = {
                id: location.state.id.S,
                ticketType: 16,
            };

            try {
                const response = await fetch(`${ENDPOINT}/getTicket`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const data = await response.json();
                setDataTicket(data.Item);
                // Puedes manejar la respuesta según tus necesidades
            } catch (error) {
                console.error("Error:", error.message);
                // Puedes manejar el error según tus necesidades
            }
        };

        fetchData();
    }, []);

    /*     useEffect(() => {
            const fetchData = async () => {
                const requestData = {
                    id: location.state.personalidadID.S,
                    ticketType: 15,
                };
                try {
                    const response = await fetch(`${ENDPOINT}/getTicket`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestData),
                    });
    
                    if (!response.ok) {
                        throw new Error(`Error en la solicitud: ${response.status}`);
                    }
    
                    const data = await response.json();
                    console.data("data-------", data);
                    setPerData(data);
                } catch (error) {
                    console.error("Error al obtener el ticket:", error);
                    // Puedes manejar el error según tus necesidades
                }
            };
    
            fetchData();
        }, [location.state]); */
    const AlertsAction = (type) => {
        if (type === "error") {
            Swal.fire({
                title: "Error!",
                text: "Fallo el proceso de actualizar de Vehiculos",
                icon: "error",
                confirmButtonText: "Cerrar",
            });
        }
        if (type === "success") {
            Swal.fire({
                title: "Exito",
                text: "Vehiculo Actualizado con exito.",
                icon: "success",
                confirmButtonText: "Aceptar",
            }).then(() => {
                console.log("Modal cerrado con éxito");
            });
        }
    };


    const RenderTable = (items) => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const handleChangePage = (event, newPage) => {
            setCurrentPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(1); // Reiniciar a la primera página cuando se cambian las filas por página
        };
        return (
            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="text-center">No.</TableCell>
                                    <TableCell className="text-center">Folio</TableCell>
                                    <TableCell className="text-center">Número de Serie</TableCell>
                                    <TableCell align="center">Modelo</TableCell>
                                    <TableCell align="center">Estado de Procedencia</TableCell>
                                    <TableCell align="center">Origen del Vehículo</TableCell>
                                    <TableCell align="center">Documentos</TableCell>
                                    {dataTicket?.status.S === "Rechazado" &&

                                        <TableCell className="text-center">Comentario</TableCell>
                                    }
                                    <TableCell align="center">
                                        Estado{" "}
                                        <HelpOutlineIcon
                                            style={{ padding: "5px" }}
                                            className="cursor- cursor-pointer"
                                            onClick={() => setHelpView2(true)}
                                        />{" "}
                                    </TableCell>
                                    {dataTicket?.status.S === "Pagado" ?
                                        <>
                                            <TableCell align="center">Número de Transacción</TableCell>
                                            <TableCell align="center">Recibo de pago</TableCell>
                                        </>
                                        :
                                        dataTicket?.status.S === "Enviado" ?
                                            <>
                                                <TableCell align="center">Tarjeta de Circulación </TableCell>
                                                <TableCell align="center">Placas </TableCell>
                                                <TableCell align="center">Permiso de Carga  </TableCell>
                                                <TableCell align="center">Número de Transacción </TableCell>
                                                <TableCell align="center">Recibo de pago</TableCell>

                                            </>
                                            :
                                            dataTicket?.status.S === "Rechazado" &&
                                            <TableCell align="center">Acciones</TableCell>

                                    }



                                </TableRow>
                            </TableHead>
                            <TableBody>{renderRows(items, page, rowsPerPage)}</TableBody>
                        </Table>
                        {filteredData.length > itemsPerPage && (
                            <Stack spacing={2} justifyContent="center" alignItems="center" direction="row">
                                <Pagination count={Math.ceil(filteredData.length / itemsPerPage)} page={currentPage} onChange={handleChange} />
                            </Stack>
                            /*     <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={Math.ceil(filteredData.length / itemsPerPage)}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                labelRowsPerPage="Filas por página:"
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              /> */
                        )}
                    </TableContainer>
                </View>
            </View>
        );
    };
    const renderRows = (items, page, rowsPerPage) => {

        return items.map((data, index) => {
            let factURL=""
            if(data.factura !== ""){

                factURL = JSON.parse(data.factura);
            }
            const jsonString = data.feedback; // Asigna el valor de data.feedback
            let objetos;
            let fed = "";
            let fecha = "";
            let feedBacks = []
            try {
                // Intenta parsear el string JSON
                objetos = jsonString ? JSON.parse(jsonString) : null;
            } catch (error) {
                // Manejo de errores si el JSON no es válido
                objetos = null;
            }
            if (Array.isArray(objetos)) {
                // Ahora puedes trabajar con el array de objetos

                feedBacks = objetos

                const formatoFinal = new Intl.DateTimeFormat('es-MX', opcionesDeFormato).format(new Date(objetos[objetos.length - 1].date));

                fed = objetos[objetos.length - 1].content;
                fecha = formatoFinal;
            } else {
                // Manejo para el caso en que el JSON no sea un array válido

            }

            return (
                <TableRow key={index}>
                    <TableCell className="text-center">{data.formularioID}</TableCell>
                    <TableCell className="text-center">#{data.ticketID}</TableCell>
                    <TableCell className="text-center">{data.numeroSerie}</TableCell>
                    <TableCell className="text-center">{`${data.modelo}`}</TableCell>
                    <TableCell className="text-center">
                        {data.estadoProcedencia}
                    </TableCell>
                    <TableCell className="text-center">{`${data.origenVehiculo}`}</TableCell>
                    <TableCell className="text-center">
                        <ul style={{padding:"0"}} >

                            {factURL !== "" && factURL.map((data, index) => (

                                <li onClick={() => handleDocumento(data.url)} style={{textDecoration:"underline", color:"blue", marginBottom:"2px"}} key={index}>
                                   Ver {data.name === "HojaRecepcion" ? "Hoja de recepción" : data.name === "tituloAmericano" ? "Titulo Americano" : data.name }
                                </li>

                            ))    
                        }
                        </ul>
                    </TableCell>
                    {dataTicket?.status.S === "Rechazado" &&
                        <TableCell className="text-center" style={{ maxWidth: "400px" }} onClick={() => { setComments(feedBacks); setViewComment(true) }}>
                            <div className="content">
                                <p className="message" style={{ width: "400px", maxWidth: "400px", textAlign: "center", margin: "auto" }} >{fed}</p>
                                <div className="date">{fecha}</div>
                            </div>


                        </TableCell>
                    }
                    <TableCell className="text-center">{data.status}</TableCell>

                    {dataTicket?.status.S === "Pagado" ?
                        <>
                            <TableCell align="center">{data.numTransaccion}</TableCell>
                            {data.reciboPagoUrl !== "" &&

                                <TableCell align="center"> <a href={data.reciboPagoUrl} target="_blank" rel="noopener noreferrer" >Descargar</a></TableCell>
                            }
                        </>
                        :
                        dataTicket?.status.S === "Enviado" ?
                            <>

                                <TableCell align="center">{data.folioTarjetaCirculacion}</TableCell>
                                <TableCell align="center">{data.placa}</TableCell>
                                <TableCell align="center">{data.folioPermisoCarga}</TableCell>
                                <TableCell align="center">{data.numTransaccion}</TableCell>
                                {data.reciboPagoUrl !== "" &&

                                    <TableCell align="center"> <a href={data.reciboPagoUrl} target="_blank" rel="noopener noreferrer" >Descargar</a></TableCell>
                                }
                            </>
                            :
                            dataTicket?.status.S === "Rechazado" &&
                            <TableCell className="text-center" >
                                {data.status === "Rechazado" && (
                                    <EditIcon
                                        onClick={() => {
                                            setShowEdit(true);
                                            setDataEdit(data);
                                        }}
                                    />
                                )}
                            </TableCell>

                    }
                </TableRow >

            );
        });
    };
    const exportExcel = (id) => {
        ObtenerExcel(id).then(result => {
            if (result.ok) {
                window.open(result.message.url)
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    };
    const [guias, setGuias] = useState("");
    const direcciones = () => {
        if (dataTicket?.seguimiento !== undefined) {
            const data = JSON.parse(dataTicket?.seguimiento?.S);

            setGuias(data);
        }

        setShowDireccion(true)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [filters, setFilters] = useState({
        numeroSerie: '',
        modelo: '',
        estadoProcedencia: '',
        status: "",
        ticketID: ''
        // Agrega más campos según tus necesidades
    });

    if (!data) {
        // Manejo de casos donde info o info.Items es undefined
        return null; // o algún otro manejo de error que prefieras
    }

    const handleFilterChange = (filterName, value) => {
        /*  if (filterName === "ticketID" || filterName === "modelo") {
             value = parseInt(value)
         } */
        setFilters({ ...filters, [filterName]: value });
        setCurrentPage(1)
    };

    const filteredData = data.filter(item => {

        const isNumericFolio =
            typeof item.ticketID === 'number' && !isNaN(item.ticketID);

        // Convertir a cadena solo si es numérico
        const itemFolio = isNumericFolio ? item.ticketID.toString() : '';

        const isNumericoModelo =
            typeof item.modelo === 'number' && !isNaN(item.modelo);

        // Convertir a cadena solo si es numérico
        const itemModelo = isNumericoModelo ? item.modelo.toString() : '';


        return (
            itemFolio.includes(filters.ticketID) &&
            item.numeroSerie?.toLowerCase().includes(filters.numeroSerie?.toLowerCase()) &&
            itemModelo?.includes(filters.modelo) &&
            item.estadoProcedencia?.toLowerCase().includes(filters.estadoProcedencia?.toLowerCase()) &&
            item.status?.toLowerCase().includes(filters.status?.toLowerCase())

            // Agrega más condiciones según tus necesidades
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };


    return (
        <>

            <div className="HeaderTramite">
                <View className="FormTitle">Emplacamiento Exprés</View>
                <View className="FormSubTitle">Detalles de Flotillas</View>
                <View className="FormDescripcionFlotillas">
                    <div className="InfoFlotillas">
                        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>RFC:</strong>
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>{dataTicket?.tipoPersonalidad?.S === "Moral" ? "Razón social:" : "Nombre:"}</strong>{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Folio flotilla:</strong>{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Estado:</strong>{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Tipo de Facturación:</strong>{" "}
                            </span>
                            {dataTicket?.status.S === "Pagado" || dataTicket?.status.S === "Enviado" || dataTicket?.status.S === "Listo para envió" ?
                                <span style={{ display: "flex", justifyContent: "center" }}>
                                    <strong>Dirección de Envío:</strong>{" "}
                                </span>
                                : null
                            }
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {dataTicket?.rfc.S}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {dataTicket?.razon_social.S}{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {dataTicket?.ticketID.N}{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "25px", height: "25px" }}>

                                    {getIconStatus(dataTicket?.status.S)}
                                </div>
                                {dataTicket?.status.S === "Generar presupuestación" ? "Listo para pago" : dataTicket?.status.S === "Listo para presupuestar" ? "Aprobada" : dataTicket?.status.S}

                                <HelpOutlineIcon
                                    style={{ padding: "5px", width: "30", height: "30" }}
                                    className="cursor- cursor-pointer"
                                    onClick={() => setHelpView(true)}
                                />{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {dataTicket?.tipoFacturacion.S}
                            </span>
                            {dataTicket?.status.S === "Pagado" || dataTicket?.status.S === "Enviado" || dataTicket?.status.S === "Listo para envió" ?

                                <span style={{ display: "flex", justifyContent: "center" }}>
                                    <Button variant="link" onClick={() => direcciones()}>Ver detalles</Button>
                                </span>
                                : null
                            }
                        </div>
                    </div>
                </View>
            </div>

            <View className="ViewPrincipal">
                <Row className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Group as={Col} style={{ textAlign: "left" }}>
                        <button type="button" onClick={() => navigate("/tramite/Historial-Flotillas")}
                            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                            <FaArrowLeft /> Volver al Inicio
                        </button>
                    </Form.Group>
                    {dataTicket?.status.S === "Pagado" || dataTicket?.status.S === "Enviado" || dataTicket?.status.S === "Listo para envió" ?
                        <Form.Group as={Col} style={{ textAlign: "right" }}>
                            <Button onClick={() => exportExcel(dataTicket?.id.S)} variant="secondary"><div style={{ display: "flex", alignItems: "center" }}>
                                Manifiesto de flotilla  <SiMicrosoftexcel style={{ marginLeft: "5px" }} /></div></Button>
                        </Form.Group>

                        : dataTicket?.status.S === "Generar presupuestación" ?
                            <Form.Group as={Col} style={{ textAlign: "right" }}>
                                <Button variant='secondary' onClick={() => navigate("/tramite/Direccion-Vehicular", { state: location.state })}> Continuar al Pago</Button>

                            </Form.Group>


                            : null
                    }
                </Row>
                <Row>

                    <Form.Group as={Col}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Control
                                    type="text"
                                    name="Folio"
                                    value={filters.ticketID}
                                    onChange={e => handleFilterChange('ticketID', e.target.value)}
                                    placeholder="Folio"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control
                                    type="text"
                                    /*  value={filters.numeroSerie} */
                                    onChange={e => handleFilterChange('numeroSerie', e.target.value)}
                                    name="numeroSerie"
                                    placeholder="No de Serie"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control
                                    type="text"
                                    maxLength={4}
                                    /*   value={filters.modelo} */
                                    onChange={e => handleFilterChange('modelo', e.target.value)}
                                    placeholder="Modelo"
                                    name="modelo"
                                />
                            </Form.Group>

                            <Form.Group as={Col}>

                                <Select
                                    value={estadosArray.find(option => option.value === filters.estadoProcedencia)}
                                    onChange={selectedOption => handleFilterChange('estadoProcedencia', selectedOption?.value)}
                                    name="estadoProcedencia"
                                    options={estadosArray}
                                    isSearchable={true}
                                    placeholder="Estado de Procedencia"
                                />

                                {/*  <Form.Select
            type="text"
            value={filters.estadoProcedencia}
            onChange={e => handleFilterChange('estadoProcedencia', e.target.value)} 
            name="estadoProcedencia"
            >
              <option value="">Estado de Procedencia</option>
              <option value="Revisión">Revisión</option>
              <option value="Aprobado">Aprobado</option>
              <option value="Rechazado">Rechazado</option>
              <option value="Bloqueado">Bloqueado</option>
            </Form.Select> */}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Select
                                    type="text"
                                    onChange={e => handleFilterChange('status', e.target.value)}
                                    value={filters.status}
                                    name="status"
                                >
                                    <option value="">Seleccionar Estado</option>
                                    <option value="Revisión">Revisión</option>
                                    <option value="Aprobado">Aprobado</option>
                                    <option value="Rechazado">Rechazado</option>
                                    <option value="Bloqueado">Bloqueado</option>
                                </Form.Select>
                            </Form.Group>

                        </Row>
                    </Form.Group>
                </Row>
                <View className="ViewContent">{RenderTable(currentItems)}</View>
            </View>
            <Modal
                show={showDireccion}
                animation={false}
                onHide={() => setShowDireccion(false)}
                centered
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>
                        Dirección de Envío
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {direccion !== "" &&

                        <div>

                            <Form.Group as={Row} style={{ backgroundColor: "lightgray" }}>
                                <Form.Label column>Nombre del Destinatario:</Form.Label>
                                <Col >
                                    <Form.Control
                                        value={direccion[0].nombreDestinatario.S}
                                        readOnly
                                        plaintext
                                    />
                                </Col>
                                <Form.Label column>Teléfono:</Form.Label>
                                <Col>
                                    <Form.Control
                                        value={direccion[0].telefono.S}
                                        readOnly
                                        plaintext
                                    />
                                </Col>

                            </Form.Group>


                            <Form.Group as={Row}>
                                <Form.Label column>Código Postal:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].codigo_postal.S}
                                    />
                                </Col>
                                <Form.Label column>Municipio:</Form.Label>
                                <Col>
                                    <Form.Control
                                        value={direccion[0].municipio.S}
                                        readOnly
                                        plaintext
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} style={{ backgroundColor: "lightgray" }}>
                                <Form.Label column>Estado:</Form.Label>
                                <Col>
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        value={direccion[0].estado.S}
                                    />
                                </Col>
                                <Form.Label column>Localidad:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].localidad.S}
                                    />
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column>Colonia:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].colonia.S}
                                    />
                                </Col>
                                <Form.Label column>Calle:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].calle.S}
                                    />
                                </Col>

                            </Form.Group>

                            <Form.Group as={Row} style={{ backgroundColor: "lightgray" }}>
                                <Form.Label column>Número Exterior:</Form.Label>
                                <Col>
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        value={direccion[0].numero_exterior.S}
                                    />
                                </Col>
                                <Form.Label column>Número Interior:</Form.Label>
                                <Col>
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        value={direccion[0].numero_interior.S === undefined ? "Sin Número Interior" : direccion[0].numero_interior.S}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column>Entre Calle 1:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].entreCalle1.S === undefined ? "" : direccion[0].entreCalle1.S}
                                    />
                                </Col>
                                <Form.Label column>Entre Calle 2:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].entreCalle2.S === undefined ? "" : direccion[0].entreCalle2.S}
                                    />
                                </Col>
                            </Form.Group>



                            <Form.Group as={Row} style={{ backgroundColor: "lightgray" }}>
                                <Form.Label column>Datos de Entrega:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].datosReferencia.S === undefined ? "" : direccion[0].datosReferencia.S}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column>Indicaciones de Entrega:</Form.Label>
                                <Col>
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        value={direccion[0].indicacionesEntrega.S === undefined ? "" : direccion[0].indicacionesEntrega.S}
                                    />
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label>Número de guia</Form.Label>
                                <Col>
                                    <ul style={{ padding: "0" }}>

                                        {guias?.numeroGuia?.map((item, index) => (
                                            <li key={index}>Codigó de seguimiento {index + 1}: <strong>{item}</strong></li>
                                        ))}

                                    </ul>
                                </Col>
                            </Form.Group>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDireccion(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={helpView}
                animation={false}
                onHide={() => setHelpView(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        El proceso de Flotilla Foránea puede transitar por los siguientes
                        estados:{" "}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>
                        <strong>Captura</strong> – La información de los vehículos de la flotilla se encuentra en proceso de captura por parte del contribuyente<br></br>
                        <strong>En Proceso</strong> – La flotilla está en proceso de generación de su expediente digital<br></br>
                        <strong>Pre-Alta</strong> - La flotilla se encuentra asignada a un equipo de analistas vehiculares y se encuentra en proceso de acreditación<br></br>
                        <strong>Rechazado</strong> – Uno de los vehículos de la flotilla ha recibido retroalimentación como parte de su proceso de acreditación<br></br>
                        <strong>Aprobada</strong> –La flotilla ha finalizado el proceso de acreditación<br></br>
                        <strong>Listo para Pago</strong> – Se ha generado el presupuesto de pago de los vehículos de la flotilla que han sido aprobados <br></br>
                        <strong>Pendiente de Pago</strong> – Se espera confirmación del pago o transferencia de la flotilla <br></br>
                        <strong>Procesando el Pago</strong> –El pago de la flotilla está siendo procesado <br></br>
                        <strong>Pagado</strong> – El pago de la flotilla ha sido procesado exitosamente y la información de la flotilla será enriquecida con las referencias de facturación correspondientes<br></br>
                        <strong>Enviado</strong> -Las placas y formas de la flotilla han sido enviadas a través de una empresa de paquetería y los números de rastreo pueden ser consultados mediante la página de seguimiento de la flotilla<br></br>
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setHelpView(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={helpView2}
                animation={false}
                onHide={() => setHelpView2(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        El proceso de Acreditación Vehicular puede transitar por los
                        siguientes estados:{" "}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Captura</strong> – El expediente digital de la Acreditación
                        Vehicular ha sido Creado, pero aún no ha sido Asignado a un Analista
                        para su revisión <br></br>
                        <strong>Revisión</strong> - El expediente ha sido asignado a un
                        Supervisor o un Analista para su proceso de Acreditación <br></br>
                        <strong>Aprobado</strong> – El expediente se considera completo y
                        correcto, por lo que dicha Personalidad puede ser utilizada para el
                        registro de Flotillas de Vehículos Foráneos <br></br>
                        <strong>Rechazado</strong> – El expediente se considera completo y
                        correcto para su presupuestación<br></br>
                        <strong>Bloqueado</strong> – El analista ha llegado a la conclusión
                        de que la información sometida para el proceso de acreditación
                        cuenta con una serie de anomalías que justifican una acción de
                        Bloqueo, siendo de manera efectiva, una cancelación de dicho
                        Vehículo para el presente y futuros trámites. <br></br>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setHelpView2(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={viewDocuent}
                animation={false}
                onHide={() => setViewDocument(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Documentos </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   
                    <Iframe
                        src={infoDocuent}
                        height="700px"
                        width="750px"
                        display="initial"
                        position="relative"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setViewDocument(false)}
                    >
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={viewComment}
                animation={false}
                onHide={() => setViewComment(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Comentarios </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {comments?.length > 0 && comments.map((com, index) => <div key={index} className="flex border border-gray-400 rounded-md p-3 my-4"><div><span className="font-bold">Comentario:</span>{com.content}</div>-<div><span className="font-bold">Fecha:</span>{new Intl.DateTimeFormat('es-MX', opcionesDeFormato).format(new Date(com.date))}</div></div>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setViewComment(false)}
                    >
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <ModalEditar
                showEdit={showEdit}
                dataEdit={dataEdit}
                setShowEdit={setShowEdit}
                infoDocuent={infoDocuent}
                setChangeInfo={setChangeInfo}
                changeInfo={changeInfo}
            />
            <ToastContainer closeOnClick />
            <div>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <h1>Esperando Respuesta</h1>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
};
