import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { fetchUserAttributes } from 'aws-amplify/auth';
import { CircularProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
export const ButtonPago = (props) => {
  const { idForm, calculo } = props;
  const [loading, setLoading] = useState(false)
  const SEI_API_ENDPOINT = process.env.REACT_APP_SEI_API_ENDPOINT
  console.log(idForm, calculo)
  function findValueByKeyInNestedObject(obj, keyToFind) {
    for (const key in obj) {
      if (key === keyToFind) {
        return obj[key];
      }
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const found = findValueByKeyInNestedObject(obj[key], keyToFind);
        if (found !== undefined) {
          return found;
        }
      }
    }
    return undefined; // Si no se encuentra la clave, devuelve undefined
  }
  const relizarPago = async () => {
    setLoading(true);
    const user = await fetchUserAttributes();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "id": idForm,
      "reference": findValueByKeyInNestedObject(calculo, 'referencia'),
      "amount": calculo.calculos?.total === undefined ? calculo?.importe === undefined ? calculo.datosGenerales?.total === undefined ? `${calculo?.estadoDeCuenta[0]?.importe}` : `${calculo.datosGenerales?.total}` : `${calculo?.importe}` : `${calculo?.calculos?.total}`,
      "email": user.email
    });
    console.log(raw)
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${SEI_API_ENDPOINT}/sei/simulate-payment`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.message !== undefined) {
          toast.success('Simulación de pago enviada con éxito', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

        } else {
          setLoading(false);
          toast.error('Error en el proceso', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

        }
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      });
  }
  return (
    <>
      <Button onClick={() => relizarPago()} variant='danger' disabled={loading}>
        {!loading ?

          "Simular Pago"
          :
          <div style={{ display: "flex" }}>
            Espere... <CircularProgress style={{ padding: "0", width: "20px", height: "20px" }} />
          </div>
        }
      </Button>
      <ToastContainer/>
    </>
  )
}
