import React, { useState, useEffect } from "react";
import { View } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { ResumenFlotillas } from "./ResumenFlotillas";
import Iframe from "react-iframe";
import FormEvo from '../FormEvo';
export const FormPagosMIT = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
  return (
    <>
    <div className="HeaderTramite">
        <View className="FormTitle">Emplacamiento Exprés</View>
        <View className="FormSubTitle">Dirección y Método de Pago</View>
        <View className="FormDescripcion"></View>
    </div>
    <Row className="mb-0">
        <Form.Group as={Col} md="5" style={{ textAlign: "left" }}>
            <button type="button" onClick={() => navigate("/tramite/Historial-Flotillas")}
                className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                <FaArrowLeft />Volver al Inicio
            </button>

        </Form.Group>
    </Row>
    <View className="ViewPrincipal">
        <View className="FormContent">
        <FormEvo sessionId={location.state.sessionId} versionId={location.state.version}/>
       {/*  <Iframe
                    src={location.state.url}
                    className="MITFrame"
                    display="initial"
                    styles={{overflow: "hidden"}}
                    position="relative"
                /> */}
        </View>
    </View>
    <ResumenFlotillas  data={location.state.data}/>


  
</>
  )
}
