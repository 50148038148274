const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT  ;

const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR  ;

export function ObtenerToken(){
    const url = `${ENDPOINT}/auth`
    const params ={
        method:"POST",
        headers: {
            "Content-Type": "application/json",
        }
    };

    return fetch(url, params).then(response => {
        return response.json()
    }).then(result =>{
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err=>{
        return {
            ok: false,
            message: err.message
        };
    })
}

export function Validar(data, token){
const url = `${ENDPOINT_SEI}/sei/vehicular/validate`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
           
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            if(result.replaqueo){

                return {
                    ok: false,
                    message: result.error,
                    replaqueo: true
                }
            }else{
                return {
                    ok: false,
                    message: result.error,
                }

            }
        }
    }).catch(err => {
        return {
            ok: false,
            message: "Error, por favor inténtelo de nuevo más tarde"
        };
    })
}
export function CalcularVehicularAPI(data, token){
const url = `${ENDPOINT_SEI}/sei/vehicular/calculate`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params).then(response => {
        return response.json();
    }).then(result => {
      
        if(result.error === undefined){

            if (result) {
                return {
                    ok: true,
                    message: result
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }else{
            return {
                ok: false,
                message: result.error
            }
        }
    }).catch(err => {
        
        return {
            ok: false,
            message: "Error, por favor inténtelo de nuevo más tarde"
        };
    })
}