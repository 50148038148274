import React, { useState } from 'react'
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik, useField } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
import MaskedInput from 'react-text-mask';
import { PhoneNumberField, Flex, Button as ButtonA } from '@aws-amplify/ui-react';
import Swal from 'sweetalert2';
import { ConstructionOutlined } from '@mui/icons-material';
import Temporizador from './Temporizador';
import { Backdrop, CircularProgress } from '@mui/material';
export const FormDatosContacto = (props) => {
    const navigate = useNavigate();
    const { datos, setChangeData, changeData } = props;
    const [editar, setEditar] = useState(datos.datosContacto !== null ? true : false);
    const [loadingSave, setLoadingSave] = useState(false);
    const dialCodeRef = React.useRef(null);
    const schema = yup.object().shape({

        telefono: yup.string().required("Este Campo es Obligatorio").matches(/^(\(\d{3}\) \d{3}-\d{4}|\d{10})$/, 'Formato no valido'),
        email: yup.string().email("El formato del correo no es válido").required("Este valor es Obligatorio"),
        otp: yup.string(),
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "telefono" || name === "otp") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else {

            values = values.toUpperCase();

            setFieldValue(name, values);
        }
    };


    const VerificarOTP = (otp) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": datos.procedureType,
            "id": datos.id,
            "phoneNumber": datos.datosContacto.telefono,
            "otp": otp,
            "sectionName": "datosContacto",
        });
        console.log(raw)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/otp/verify", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.verified === true) {
                    toast.success(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setChangeData(!changeData)
                } else {
                    toast.error(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            })
            .catch((error) => console.error(error));
    }

    const ObtenerOTP = (number, reenviar) => {
        const cleanedPhoneNumber = number.replace(/[^\d]/g, '');
        const telefono = `${dialCodeRef.current.value}${cleanedPhoneNumber}`
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": datos.procedureType,
            "id": datos.id,
            "phoneNumber": reenviar === true ? `${datos.datosContacto.codigoPais}${number}` : telefono,
            "sectionName": "datosContacto"
        })
        console.log(raw)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://6ugck717sl.execute-api.us-east-1.amazonaws.com/dev/otp", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                setChangeData(!changeData)
                if (result.error === undefined) {
                    Swal.fire({
                        text: "Se ha enviado un número de verificacíon al número de telefono, favor de ingresarlo para verificar el número ",
                        icon: "success",
                        confirmButtonColor: "#607472",
                        confirmButtonText: "Aceptar"
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                    });
                } else {
                    toast.error(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setLoadingSave(false)
            })
            .catch((error) => {
                console.error(error)
                setLoadingSave(false)
            });
    }
    const CustomPhoneNumberField = ({ name, disabled, touched, errors }) => {
        const [field, meta, helpers] = useField(name);

        const { setValue } = helpers;

        const Validar = (e) => {
            setValue(e.target.value)
        }
        return (
            <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(999) 999-9999"
                value={field.value}
                onChange={(e) => Validar(e)}

                render={(inputRef, props) => (
                    <PhoneNumberField
                        {...props}
                        defaultDialCode={datos?.datosContacto?.codigoPais !== undefined ? datos.datosContacto.codigoPais : "+52"}
                        dialCodeRef={dialCodeRef}
                        labelHidden
                        isDisabled={disabled}

                        ref={inputRef} // <-- Aquí cambiamos inputRef por ref
                        hasError={meta.touched && !!meta.error}
                        className={` ${touched.telefono && errors.telefono ? 'is-invalid' : touched.telefono ? 'is-valid' : ''}`}

                    />
                )}
            />
        );
    };
    return (
        <>

            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{

                            telefono: datos?.datosContacto?.telefono !== undefined ? datos.datosContacto.telefono : "",
                            email: datos?.datosContacto?.correo !== undefined ? datos.datosContacto.correo === null ? "" : datos.datosContacto.correo : "",
                            otp: "",
                        }}
                        onSubmit={async values => {
                            setLoadingSave(true);
                            const cleanedPhoneNumber = values.telefono.replace(/[^\d]/g, '');
                            const telefono = `${dialCodeRef.current.value}${cleanedPhoneNumber}`
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": datos.type,
                                "usuarioID": user.username,
                                "datosContacto": {
                                    "telefono": cleanedPhoneNumber,
                                    "correo": values.email,
                                    "codigoPais": `${dialCodeRef.current.value}`,
                                    "isComplete": false
                                },

                            }

                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setEditar(true)
                                    if (datos.datosContacto === null || datos.datosContacto.telefono !== telefono) {
                                        ObtenerOTP(values.telefono, false);
                                    }
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setLoadingSave(false);

                                }
                            }).catch(error => {
                                console.log(error)
                                setLoadingSave(false);
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue, resetForm }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>

                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>Datos de Contacto</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Telefono*</Form.Label>
                                        <Flex direction="row" >
                                            <CustomPhoneNumberField name="telefono" disabled={editar && datos?.datosContacto?.isComplete === true} touched={touched} errors={errors} />
                                            {/*  <Button size='small' onClick={()=>ObtenerOTP(values.telefono)}>Verificar</Button>  */}
                                        </Flex>


                                        {/* <MaskedInput
                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            placeholder="(999) 999-9999"
                                            value={values.telefono}
                                            onChange={(event) => setFieldValue('telefono', event.target.value)}
                                            disabled={editar}
                                            className={`form-control ${touched.telefono && errors.telefono ? 'is-invalid' : touched.telefono ? 'is-valid' : ''}`}
                                        /> */}
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.telefono}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {datos?.datosContacto?.otpData !== undefined && datos.datosContacto.otpData.verificado === false && editar === true &&

                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Ingrese el codigo de verificacíon</Form.Label>
                                            <Temporizador tiempo={datos.datosContacto.otpData.tiempoExp} />
                                            <InputGroup>
                                                <Form.Control
                                                    type='text'
                                                    name="otp"
                                                    value={values.otp}
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                />
                                            </InputGroup>
                                            <Button className='m-1' variant='secondary' onClick={() => ObtenerOTP(datos.datosContacto.telefono, true)}>Reenviar Código</Button>
                                            <Button className='m-1' variant='secondary' onClick={() => VerificarOTP(values.otp)}>Verificar</Button>
                                        </Form.Group>
                                    </Row>
                                }
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Correo*</Form.Label>
                                        <Form.Control
                                            name='email'
                                            type='email'
                                            placeholder='Correo'
                                            disabled={editar && datos?.datosContacto?.isComplete === true}
                                            value={values.email}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                                    <>
                                        {

                                            datos.type !== 234 &&

                                            <Row className='mb-3'>

                                                <Form.Group as={Col} className='d-grid'>
                                                    {editar === false && datos.datosContacto !== null ?

                                                        <Button variant='secondary' onClick={() => {
                                                            setEditar(true)
                                                            resetForm()
                                                        }} >CANCELAR</Button>
                                                        :
                                                        <Button variant='secondary' disabled={datos.datosContacto !== null ? false : true} onClick={() => setEditar(false)} >EDITAR</Button>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} className='d-grid'>

                                                    <Button variant='secondary' disabled={editar && datos?.datosContacto?.isComplete === true} type='submit'>GUARDAR</Button>
                                                </Form.Group>
                                            </Row>
                                        }
                                    </>
                                    : null

                                }
                            </Form>
                        )}
                    </Formik>
                    <ToastContainer />
                </View>
            </View>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
