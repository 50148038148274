import React from 'react'
import FormEvo from './FormEvo'
import { View } from '@aws-amplify/ui-react';
import { useLocation } from "react-router-dom";
export const FormPagosEvo = () => {
    const location = useLocation();
    console.log(location)
  return (
    <>
     <div className="HeaderTramite">
          <View className="FormTitle">Formulario de Pagos</View>
          <View className="FormDescripcion"></View>
        </div>
        <View className="ViewPrincipal">

        <div className='DivHistorial'>

        <FormEvo sessionId={location.state.sessionId} versionId={location.state.version}/>
        </div>
        
        </View>
    </>
  )
}
